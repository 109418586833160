import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  brandCommentSaver,
  CommentUpdate,
  ExFactoryDateVariable,
  getSrtInitialList,
  reqRecDate,
  savecloseToggle,
} from "../../../../Redux/actions/Srt/Srt";
import ChatLogo from "../../../../images/chat.png";
import InputField from "../../../../UIComponents/Input/Input";
import SelectLogo from "../../../../images/Select.png";
import CloseLogo from "../../../../images/Close.png";
import EmptyChat from "../../../../images/EmptyChat.png";
import { srtDataSaver } from "../../../../Redux/actions/Srt/Srt";
import "./QtyReqRec.css";
import TickLogo from "../../../../images/tick.png";
import CrossLogo from "../../../../images/cross.png";

import TextArea from "antd/es/input/TextArea";
import { Button, Modal, Tooltip } from "antd";
import { toast } from "../../../../UIComponents/Toast";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const QtyReqRec = ({
  data,
  promisedDate,
  requestRecievedDate,
  qtyReciever,
  commentReciever,
  itemData,
  dataOfReq,
  reqNo,
  dataOfRow,
  qtyRecieved,
  user,
}) => {

  const [mappedData, setMappedData] = useState([])
  useEffect(() => {
    if (data?.quantity_cmt && Array.isArray(data.quantity_cmt)) {
      const mapData = data.quantity_cmt.reduce((acc, item) => {
        const existingUserIndex = acc.findIndex(user => user.username === item.username);
  
        if (existingUserIndex !== -1) {
          acc[existingUserIndex].comments.push(item.comments);
          acc[existingUserIndex].last_comment = item.comments;
          acc[existingUserIndex].last_id = item.id;
          acc[existingUserIndex].updated_at = item.updated_at;
          acc[existingUserIndex].comment_count = item.comment_count;
        } else {
          acc.push({
            username: item.username,
            comments: [item.comments],
            last_comment: item.comments,
            last_id: item.id,
            updated_at: item.updated_at,
            comment_count: item.comment_count
          });
        }
  
        return acc;
      }, []);
      setMappedData(mapData);
    } else {
      setMappedData([]);
    }
  }, [data]);

  const dispatch = useDispatch();
  const dateFormat = "MM/DD/YYYY";
  const isToday = dayjs().format(dateFormat);
  console.log("isTodayisTodayisToday", isToday);
  const exFactoryDateVari = useSelector(
    (state) => state?.Srt?.exFactoryDateVar
  );
  const reqRecivedData = useSelector((state) => state?.Srt?.reqRecDateVar);
  console.log("reqRecivedData", reqRecivedData);
  const brandNameFromApi = useSelector((state) => state?.Srt?.srtBrandNames);
  const userDetails = useSelector((state) => state?.Profile?.profileDetails);
  const profileDetailData = useSelector(
    (state) => state?.Profile?.profileDetails?.role
  );
  const userNameMicrosoft = useSelector(
    (state) => state?.Profile?.graphCompleteData?.displayName
  );
  console.log("userNameMicrosoft", userNameMicrosoft);
  const { instance, accounts } = useMsal();
  const [inputValue, setInputVlaue] = useState("");
  const [showElement, setShowElement] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [TooltipInputText, setTooltipInputText] = useState("");
  const [reqNumber, setReqNumber] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [commentUpdateReqData, setCommentUpdateReqData] = useState({});
  const [onEditBtnClicked, setOnEditBtnClicked] = useState(false);
  const [indexValue, setIndexValue] = useState("");
  const [hoverClick, setHoverClick]  = useState("hover")
  const [comment_count, setCommentCount] = useState();
  const [userExists, setUserExists] = useState();

  const ontooltipInputTextChange = (e) => {
    setTooltipInputText(e.target.value);
  };

  const onCommentEdit = (item, index, requestNo, dataOfRow) => {
    setTooltipInputText(item?.last_comment);
    setCommentCount(item?.comment_count)
    setIndexValue(index);
    setReqNumber(requestNo);
    setCommentUpdateReqData(dataOfRow);
    setOnEditBtnClicked(true);
  };

  const onCloseClick = () => {
    setOnEditBtnClicked(false);
  };

  const onInputChange = (e) => {
    const inputValue = e.target.value.slice(0, 3);
    console.log("inputValue",inputValue);
    // if (qtyReciever === 0) e.target.value = 0;
    dispatch(reqRecDate({ ...reqRecivedData, QtyRcd: inputValue }));
    setInputVlaue(inputValue);
    setShowElement(true);
    // dispatch(savecloseToggle(true))
  };

  const onInputTickClick = () => {
    if (!inputValue) {
      toast.error("Please enter the Quantity Recieved!");
    } else if (reqRecivedData?.request_recieved === null) {
      dispatch(
        srtDataSaver(
          itemData?.style,
          itemData?.brand,
          itemData?.factory,
          itemData?.size,
          itemData?.season,
          itemData?.color_code,
          dataOfReq?.request_no,
          "",
          "",
          isToday,
          inputValue
        )
      );
      setShowElement(false);
      setInputVlaue("");
    } else {
      dispatch(
        srtDataSaver(
          itemData?.style,
          itemData?.brand,
          itemData?.factory,
          itemData?.size,
          itemData?.season,
          itemData?.color_code,
          dataOfReq?.request_no,
          "",
          "",
          reqRecivedData?.request_recieved,
          inputValue
        )
      );
      setShowElement(false);
      setInputVlaue("");
    }
  };

  const onInputCloseClick = () => {
    setShowElement(false);
    setInputVlaue("");
  };

  const toolTipCommentsData = () => {
    return {
      style: dataOfRow?.style,
      brand: dataOfRow?.brand,
      factory: dataOfRow?.factory,
      season: dataOfRow?.season,
      color_code: dataOfRow?.color_code,
      request_no: reqNumber,
      size: commentUpdateReqData?.size,
      comments: TooltipInputText,
      username: userNameMicrosoft,
      role_name: userDetails?.role,
      comment_count,
      comment_type: "Quantity",
    };
  };

  const toolTipCommentsUpdateData = (id,count) => {
    return {
      style: dataOfRow?.style,
      brand: dataOfRow?.brand,
      factory: dataOfRow?.factory,
      season: dataOfRow?.season,
      color_code: dataOfRow?.color_code,
      request_no: reqNumber,
      size: commentUpdateReqData?.size,
      comments: TooltipInputText,
      username: userNameMicrosoft,
      role_name: userDetails?.role,
      comment_count: count,
      id: id,
    };
  };

  const onTickClick = (id,count) => {
    if (!TooltipInputText) {
      toast.error("Please add the comment");
    } else {
      dispatch(CommentUpdate(toolTipCommentsUpdateData(id,count), brandNameFromApi));
    }
  };

  const onBrandEmptyCommentClick = (count, requestNo, dataOfRow) => {
    setCommentCount(count)
    setReqNumber(requestNo);
    setTooltipInputText("")
    setCommentUpdateReqData(dataOfRow);
    setIsModalOpen(true);
  };

  const onQtyCommentClick = (requestNo, dataOfRow) => {
    setReqNumber(requestNo);
    setCommentUpdateReqData(dataOfRow);
    setShowTooltip(true);
  };

  const onQtyEmptyCommentClick = (requestNo, dataOfRow) => {
    setReqNumber(requestNo);
    setCommentUpdateReqData(dataOfRow);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onSaveHandle = () => {
    if (!TooltipInputText) {
      toast.error("Please add the comment");
    } else {
      dispatch(brandCommentSaver(toolTipCommentsData(), brandNameFromApi));
      setIsModalOpen(false);
    }
  };

  const onBrandCommentClick = (count, requestNo, dataOfRow) => {
    console.log("here is data", requestNo, dataOfRow);
    setHoverClick("hover")
    setCommentCount(count + 1)
    setReqNumber(requestNo);
    setTooltipInputText("")
    setCommentUpdateReqData(dataOfRow);
    setIsModalOpen(true);
  };
  const [filteredUserName, setFilteredUserName] = useState([]);
  const userName = [];
  useEffect(() => {
    if (
      data?.quantity_cmt &&
      Array?.isArray(data?.quantity_cmt) &&
      userNameMicrosoft
    ) {
      data?.quantity_cmt?.map((item, index) => {
        userName.push(item?.username);
        if (userName && Array?.isArray(userName)) {
          const filteredUser = userName.filter(
            (name) => name === userNameMicrosoft
          );
          setFilteredUserName(filteredUser);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    if (mappedData) {
      const User = mappedData.some(item => item.username.includes(userNameMicrosoft));
      setUserExists(User)
    }
  },[mappedData])

  const comment =
    data?.quantity_cmt && Array?.isArray(data?.quantity_cmt) ? (
      <>
      <p className="tooltip-comment">
        <b>Comment</b>
      </p>
      {mappedData?.map((item, index) => {
        return (
          <>
            <div key={item.last_id} className="tooltip-name-date-container">
              <b className="tooltip-comment">
                {item?.username}
              </b>
              <p className="tooltip-comment-date-time">{item?.updated_at}</p>
            </div>
            {onEditBtnClicked && item?.username === userNameMicrosoft ?
                <>
                <TextArea
                  value={TooltipInputText}
                  rows={2}
                  onChange={ontooltipInputTextChange}
                />
                <div className="tick-cross-container">
                  <img
                    className="tick-and-cross"
                    onClick={() => onTickClick(item?.last_id,item?.comment_count)}
                    src={TickLogo}
                    alt="tick"
                  />
                  <img
                    className="tick-and-cross"
                    onClick={onCloseClick}
                    src={CrossLogo}
                    alt="cross"
                  />
                </div>
              </> :
              <>
              {item.comments.map((comment, index) => (
                  <div key={index}>
                    {comment.split(/(?<=\n)(?=[a-zA-Z])/).map((line, lineIndex) => (
                      <p key={lineIndex}className="tooltip-comment">
                         {lineIndex === 0 ? `${index + 1}. ${line}` : line}
                      </p>
                    ))}
                  </div>
                ))}
                {item?.username == userNameMicrosoft ? (
                  <div className="edit-add-container">
                    <div className="edit-icon-container">
                    <EditOutlined
                      onClick={() =>
                        onCommentEdit(item, index, reqNo, dataOfRow)
                      }
                    />
                    </div>
                    <div>
                    <PlusOutlined
                    className="comment-edit"
                    onClick={() => onBrandCommentClick(item?.comment_count, reqNo, dataOfRow)}
                  />
                  </div>
                    </div>
                ) : (
                  <></>
                )}
                </>
            }
          </>
        )
      })}
      {
             !userExists ? (
               <div className="add-button-container">
                 <div>
                   <PlusOutlined
                   className="comment-edit"
                   onClick={() => onBrandEmptyCommentClick(0, reqNo, dataOfRow)}
                   />
                 </div>
               </div>
             ) : <></>
           }
    </>
  ) : (
    <></>
    );

  useEffect(() => {
    if (data?.quantity_cmt && Array?.isArray(data?.quantity_cmt)) {
      data?.quantity_cmt?.map((item) => {
        setCommentData(item);
      });
    }
  }, [data]);

  const handleTextAreaClick = (requestNo, dataOfReq) => {
    dispatch(
      reqRecDate(
        reqRecivedData?.request_no === requestNo ? reqRecivedData : dataOfReq
      )
    );
    console.log("iam clicked now", requestNo, dataOfReq);
  };

  const onTooltipOpen = (value) => {
    if (value === true) {
      setHoverClick("click")
    }
    else {
      setHoverClick("hover")
    }
  }

  return (
    <div className="srt-table-data-four">
      <div className="input-comment-container">
        <div className="srt-input-field">
          <div className="value-and-comment">
            {qtyRecieved == null ? (
              <p>{`${qtyReciever} / `}</p>
            ) : (
              <p>{`${qtyReciever} / ${qtyRecieved}`}</p>
            )}
          </div>
          <div className="input-and-comment">
            <InputField
              use
              controls={false}
              onChange={onInputChange}
              onClick={() => handleTextAreaClick(reqNo, dataOfReq)}
              onInput={(e) => {
                const regex = /^[a-zA-Z0-9]*$/;
                e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                if (!regex.test(e.target.value)) {
                  e.preventDefault();
                }
              }}
              // disabled={qtyReciever == 0}
              type="Number"
              value={inputValue}
              maxLength={3}
            />
            {commentData?.comments == " " ||
            commentData?.comments == "" ||
            commentData?.comments == null ||
            commentData?.comments == undefined ? (
              <img
                className="comment-logo"
                onClick={() => onBrandEmptyCommentClick(0, reqNo, dataOfRow)}
                src={EmptyChat}
                alt="chatlogo"
              />
            ) : (
              <Tooltip
                onOpenChange={onTooltipOpen}
                trigger={hoverClick}
                overlayInnerStyle={{
                  width: "fit-content",
                  height: "fit-content",
                  padding: "20px",
                  color: "black",
                }}
                color={"white"}
                placement="right"
                title={comment}
              >
                <img
                  className="comment-logo"
                  // onClick={() => onBrandCommentClick(reqNo, dataOfRow)}
                  src={ChatLogo}
                  alt="chatlogo"
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="select-close-logo">
          {showElement ? (
            <>
              <img
                className="select-image"
                onClick={onInputTickClick}
                src={SelectLogo}
                alt="selectlogo"
              />
              <img
                className="close-image"
                onClick={onInputCloseClick}
                src={CloseLogo}
                alt="closelogo"
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Modal
        title="Comment"
        open={isModalOpen}
        onOk={onSaveHandle}
        onCancel={handleCancel}
        okText={"save"}
      >
        <TextArea
          value={TooltipInputText}
          rows={4}
          onChange={ontooltipInputTextChange}
          // disabled={qtyReciever == 0}
        />
        <div className="srt-buttons">
          <Button className="modal-cancel-btn" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="modal-yes-button"
            // disabled={qtyReciever == 0}
            onClick={onSaveHandle}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default QtyReqRec;
