import React from 'react'
import { Input } from 'antd';
import '../Input/Input.css'

function InputField({onClick, type, placeholder, name, value, onChange, id, suffix, className,onInput,onKeyPress,onKeyDown,maxLength,disabled,onmousedown }) {
  return (
    <div>
      <Input
        placeholder={placeholder}
        name={name}
        onStep={null} 
        value={value}
        onChange={onChange}
        id={id}
        suffix={suffix}
        type={type}
        disabled={disabled}
        onInput={onInput}
        className={className}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onClick={onClick}
        onmousedown={onmousedown}
        maxLength={maxLength} />
        
    </div>
  )
}

export default InputField