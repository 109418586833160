import React from "react";
import "./App.css";
import ComponentsRoutes from "./Components/Routes/ComponentsRoutes";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import AxiosInterceptor from "./Redux/AxiosInterceptor";

const App = () => {
  let secure_token = Cookies.get("access_token");
  const path = location.pathname;
  if (!secure_token && path?.includes("/sample-request-form/")) {
    localStorage.setItem("srfPath", location?.pathname);
  }
  if (!secure_token && path?.includes("/sample-receipt-tracker/")) {
    localStorage.setItem("srtPath", location?.pathname);
  }
  return (
    <div className="App">
      <AxiosInterceptor>
        <ComponentsRoutes />
      </AxiosInterceptor>
    </div>
  );
};

export default App;
