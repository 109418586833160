import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import store from './Redux/store'
import { Provider } from 'react-redux'
import { Toast } from './UIComponents/Toast';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
            <Toast/>
        </BrowserRouter>
    </Provider>
);

reportWebVitals();


