import React from 'react'
import './loader.css'
import { BeatLoader } from 'react-spinners'

export const Loader = (props) => {
  return (
    <div className='loader-container'>
        <BeatLoader  
        color={'#0C3D5E'}
        cssOverride={{ 
        borderColor: "#0C3D5E"}}
        loading={props.states}
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"/>
    </div>
  )
}
