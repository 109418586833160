import React, { useState } from 'react'
import Note from "../../../images/notes.png"
import emptyNote from "../../../images/sticky-note.png"
import "../PdSampleRequestForm.css"
import { Tooltip } from 'antd'
const Notes = ({note}) => {
  console.log("note",note);
  const [hoverClick, setHoverClick]  = useState("hover")
    const onTooltipOpen = (value) => {
        if (value === true) {
          setHoverClick("hover")
        }
        else {
          setHoverClick("hover")
        }
      }

    const singleNote = (
      <p style={{ whiteSpace: 'pre-line' }}>{note}</p>
    )

  return (
    <div>
        {note === null || note === undefined || note === "" ? 
            <img
            className="notes-logo"
            src={emptyNote}
            alt="emptyNote"
        />
            :
            <Tooltip
            onOpenChange={onTooltipOpen}
            trigger={hoverClick}
            overlayInnerStyle={{
              width: "fit-content",
              height: "fit-content",
              padding: "20px",
              color: "black",
            }}
            color={"white"}
            placement="right"
            title={<p style={{ whiteSpace: 'pre-line' }}>{note}</p>
          }
          >
            <img
            className="notes-logo"
            src={Note}
            alt="Note"
        /></Tooltip>}
    </div>
  )
}

export default Notes