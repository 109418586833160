import React from 'react'
import { DatePicker } from 'antd';
import { useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { resetSrfFields, srfExFactoryDate } from '../../../Redux/actions/Srf/Srf';

const RequestDatePicker = ({ createdDate,reqDate,reqStatus }) => {
    const dispatch = useDispatch();
    const dateFormat = "MM/DD/YYYY";
    const isToday = dayjs().format(dateFormat);
    const [date, setDate] = useState(isToday);
    const srfFieldData = useSelector((state) => state?.Srf?.srfFieldsReset)
    console.log("srfFieldData",srfFieldData);
  
    const onDateChange = (date, dateString) => {
      dispatch(resetSrfFields({...srfFieldData, req_exfactory:dateString}))
      dispatch(srfExFactoryDate(dateString));
      setDate(dateString);
    };
  
    const disabledDate = (current) => {
       // disable dates before today
       const currentDate = dayjs(createdDate);
       if (current < currentDate.startOf('day')) {
         return true;
       }
       // disable dates after 3 months from today
      //  const threeMonthsLater = currentDate.add(3, 'months');
      //  if (current > threeMonthsLater.endOf('day')) {
      //    return true;
      //  }
       return false;
    };
    // const datePickerFunction = () => {
    //     if (reqDate !== null || reqDate !== "") {
    //       const dateObj = dayjs(reqDate);
    //       const formattedDate = dateObj.format('MM/DD/YYYY');
    //       return (
    //         <DatePicker allowClear={false}  defaultValue={dayjs(formattedDate, "MM/DD/YYYY")} disabled={reqStatus == "1"} disabledDate={disabledDate} onChange={onDateChange} format="MM/DD/YYYY" />
    //       );
    //     }
    //      else {
    //       return (
    //         <DatePicker allowClear={false} onChange={onDateChange} disabledDate={disabledDate} disabled={reqStatus == "1"} format="MM/DD/YYYY" />
    //       );
    //     }
    //   }

    const dateObj = dayjs(reqDate);
    const formattedDate = dateObj.format('MM/DD/YYYY');

    return (
        <div className="srt-table-data-three">
      <div className="srt-date-pickers">
        <p className="srt-datepicker">
          {(reqDate === "None" || reqDate === "" || reqDate === null) ? <DatePicker allowClear={false} onChange={onDateChange} disabledDate={disabledDate} disabled={reqStatus == "1"} format="MM/DD/YYYY" /> : 
          <DatePicker allowClear={false}   disabled={reqStatus == "1"} disabledDate={disabledDate} onChange={onDateChange} format="MM/DD/YYYY" />}
        </p>
      </div>
    </div>
    )

}

export default RequestDatePicker