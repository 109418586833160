import { combineReducers } from "redux";
import { DashboardReducer } from "./reducers/Dashboard/Dashboard";
import { SrfReducer } from "./reducers/Srf/Srf";
import { SrtReducer } from "./reducers/Srt/Srt";
import { ProfileReducer } from "./reducers/Profile/Profile";

const rootReducer = combineReducers ({
     Dashboard : DashboardReducer,
     Srt: SrtReducer,
     Srf: SrfReducer,
     Profile: ProfileReducer,
  })

export default rootReducer;