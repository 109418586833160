import { Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changingTabsWithCustomButton,
  dashboardStyleList,
} from "../../Redux/actions/Dashboard/Dashboard";
import { srfRequestData } from "../../Redux/actions/Srf/Srf";
import {
  massSearchFilter,
  resetMassFilter,
  resetSeasonFilter,
  selectUnselectSrtTableRows,
  srtEXportArray,
} from "../../Redux/actions/Srt/Srt";
import { Loader } from "../../UIComponents/Loader";
import Header from "../Header/Header";
import "./HomePage.css";
const { TabPane } = Tabs;

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const changeTabsToggle = useSelector((state) => state?.Dashboard?.changeTabs);
  const statusSaverloading = useSelector(
    (state) => state?.Srt?.statusSaverLoader
  );
  const loadingForLoader = useSelector(
    (state) => state?.Dashboard?.loadingForLoader
  );
  const sendSampleloading = useSelector(
    (state) => state?.Srf?.sendSampleLoader
  );
  const srfSearchLoader = useSelector((state) => state?.Srf?.searchLoader);
  const designLoaderForSrt = useSelector(
    (state) => state?.Srt?.designStatusSaverLoader
  );
  const dashboardDataLoader = useSelector(
    (state) => state?.Dashboard?.listLoader
  );

  console.log("loadingForLoader",loadingForLoader);

  const [tabToggle, keyFromRedux] = changeTabsToggle.split(".");

  const path = location.pathname;
  console.log("path", path);

  if (path === "/dashboard") {
    localStorage.setItem("activeKey", "1");
  } else if (path === "/sample-request-form") {
    localStorage.setItem("activeKey", "2");
  } else if (path == "/sample-receipt-tracker/dickies") {
    localStorage.setItem("activeKey", "3");
    localStorage.setItem("srtActiveKey", "1");
  }
  if (path === "/sample-receipt-tracker/saucony") {
    localStorage.setItem("activeKey", "3");
    localStorage.setItem("srtActiveKey", "2");
  }
  if (path == "/sample-receipt-tracker/eddie-bauer") {
    localStorage.setItem("activeKey", "3");
    localStorage.setItem("srtActiveKey", "3");
  }
  if (path == "/sample-receipt-tracker/pd") {
    localStorage.setItem("activeKey", "3");
    localStorage.setItem("srtActiveKey", "4");
  }
  if (path == "/sample-receipt-tracker/sales-samples") {
    localStorage.setItem("activeKey", "3");
    localStorage.setItem("srtActiveKey", "5");
  }
  if (path == "/sample-receipt-tracker/unsolicited-samples") {
    localStorage.setItem("activeKey", "3");
    localStorage.setItem("srtActiveKey", "6");
  }
  const srtPathRedirect = localStorage.getItem("srtPath");

  const EmailUrlSrt = srtPathRedirect ? srtPathRedirect : path;

  if (
    EmailUrlSrt.includes("/sample-receipt-tracker/dickies/Dropped-Rejected")
  ) {
    const prefix = "/sample-receipt-tracker/dickies/Dropped-Rejected";
    const params = EmailUrlSrt.slice(prefix.length).split("/");
    const formattedDate = params.filter(Boolean).join("/");

    console.log("hasParams", params);
    localStorage.setItem("activeKey", "3");
    localStorage.setItem("srtActiveKey", "1");
    localStorage.setItem(
      "srtData",
      JSON.stringify({
        brand: "Dickies",
        style: "",
        factory: "",
        rejected_date: formattedDate,
        sale_sample: 0
      })
    );
  }

  if (
    EmailUrlSrt.includes("/sample-receipt-tracker/saucony/Dropped-Rejected")
  ) {
    const prefix = "/sample-receipt-tracker/saucony/Dropped-Rejected";
    const params = EmailUrlSrt.slice(prefix.length).split("/");
    const formattedDate = params.filter(Boolean).join("/");

    localStorage.setItem("activeKey", "3");
    localStorage.setItem("srtActiveKey", "2");
    localStorage.setItem(
      "srtData",
      JSON.stringify({
        brand: "Saucony",
        style: "",
        factory: "",
        rejected_date: formattedDate,
        sale_sample: 0
      })
    );
  }
  if (
    EmailUrlSrt.includes("/sample-receipt-tracker/eddie-bauer/Dropped-Rejected")
  ) {
    const prefix = "/sample-receipt-tracker/eddie-bauer/Dropped-Rejected";
    const params = EmailUrlSrt.slice(prefix.length).split("/");
    const formattedDate = params.filter(Boolean).join("/");

    console.log("logggingon");

    localStorage.setItem("activeKey", "3");
    localStorage.setItem("srtActiveKey", "3");
    localStorage.setItem(
      "srtData",
      JSON.stringify({
        brand: "EddieBauer",
        style: "",
        factory: "",
        rejected_date: formattedDate,
        sale_sample: 0
      })
    );
  }
  if (EmailUrlSrt.includes("/sample-receipt-tracker/pd/Dropped-Rejected")) {
    const prefix = "/sample-receipt-tracker/pd/Dropped-Rejected";
    const params = EmailUrlSrt.slice(prefix.length).split("/");
    const formattedDate = params.filter(Boolean).join("/");

    console.log("logggingon");

    localStorage.setItem("activeKey", "4");
    localStorage.setItem("srtActiveKey", "4");
    localStorage.setItem(
      "srtData",
      JSON.stringify({
        brand: "PD",
        style: "",
        factory: "",
        rejected_date: formattedDate,
        sale_sample: 0
      })
    );
  }
  if (tabToggle == "true") {
    dispatch(changingTabsWithCustomButton(`false.3`));
  }
  const onKeyChange = (key) => {
    if (key == 1) {
      navigate("/dashboard");
      localStorage.setItem("activeKey", "1");
      localStorage.removeItem("srfPath");
      localStorage.removeItem("srtPath");
      localStorage.removeItem("currentPage");
      localStorage.removeItem("srtMassData");
      localStorage.removeItem("SrfSearchData");
      dispatch(dashboardStyleList());
    } else if (key == 2) {
      navigate("/sample-request-form");
      localStorage.setItem("activeKey", "2");
      localStorage.removeItem("srtPath");
      localStorage.removeItem("srtMassData");
      localStorage.removeItem("currentPage");
      localStorage.removeItem("SrfSearchData");
    } else if (key == 3) {
      navigate("/sample-receipt-tracker/dickies");
      localStorage.setItem("activeKey", "3");
      localStorage.setItem("srtActiveKey", "1");
      localStorage.removeItem("srfPath");
      localStorage.removeItem("currentPage");
      localStorage.removeItem("srtMassData");
      localStorage.removeItem("SrfSearchData");
      localStorage.setItem(
        "srtData",
        JSON.stringify({
          brand: "Dickies",
          style: "",
          factory: "",
          rejected_date: "",
          sale_sample: 0
        })
      );
      dispatch(srtEXportArray([]));
      dispatch(selectUnselectSrtTableRows([]));
      dispatch(resetSeasonFilter([]));
      dispatch(resetMassFilter([]));
      dispatch(srfRequestData(false));
    }
  };
  const goBack = () => {
    window.history.back();
  };

  useEffect(() => {
    dispatch(dashboardStyleList());
  }, []);

  const activeKey = localStorage.getItem("activeKey");

  return (
    <div className="home-page-conatiner">
      {loadingForLoader ? <Loader /> : null}
      {srfSearchLoader ? <Loader /> : null}
      {sendSampleloading ? <Loader /> : null}
      {statusSaverloading ? <Loader /> : null}
      {designLoaderForSrt ? <Loader /> : null}
      {dashboardDataLoader ? <Loader /> : null}

      <Header />
      <div className="tabs-main">
        <div className="tabs-container">
          <Tabs
            type="card"
            disabled={true}
            activeKey={activeKey ? activeKey : "1"}
            onChange={onKeyChange}
          >
            <TabPane
              onClick={() => onKeyChange("1")}
              key={"1"}
              tab={<div className="tab1">Dashboard</div>}
            >
              {/* <Dashboard /> */}
            </TabPane>
            <TabPane
              onClick={() => onKeyChange("2")}
              key={"2"}
              tab={<div className="tab2">Sample Request Form</div>}
            ></TabPane>
            <TabPane
              onClick={() => onKeyChange("3")}
              key={"3"}
              tab={<div className="tab3">Sample Receipt Tracker</div>}
            ></TabPane>
          </Tabs>
        </div>
        <span className="back-wrapper">
          {/* <p onClick={goBack} className='tab-back'>Back</p> */}
        </span>
      </div>
    </div>
  );
};

export default HomePage;
