import { DatePicker } from "antd";
import DropDown from "../../UIComponents/CustomDropDown/SrtDropDown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { srtReportGeneration } from "../../Redux/actions/Srt/Srt";
import Button from "../../UIComponents/Button/Button";
import "./Srt.css";
import SrtTabs from "./SrtTabs";
import { toast } from "../../UIComponents/Toast";

const Srt = () => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const styleListData = useSelector((state) => state?.Dashboard?.styleList);
  const [brandOptions, setBrandOptions] = useState([]);
  console.log("styleListDatadd", styleListData);

  useEffect(() => {
    if (styleListData && Array?.isArray(styleListData)) {
      const brandArray = styleListData?.map((item) => item?.brand);
      setBrandOptions([...new Set(brandArray)]);
    }
  }, [styleListData]);

  const [brandId, setBrandId] = useState();
  const [selectedRange, setSelectedRange] = useState([]);
  const [onSubmitClearDate, setOnSubmitClearDate] = useState([]);
  const [seasonFilter, setSeasonFilter] = useState();

  const seasons = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "F24",
      label: "F24",
    },
    {
      value: "S25",
      label: "S25",
    },
    {
      value: "F25",
      label: "F25",
    },
  ]
  
  const option = [
    {
      value: "All",
      label: "All",
    },
    {
      value: "Aims",
      label: "Aims",
    },
    {
      value: "Ayesha",
      label: "Ayesha",
    },
    {
      value: "Bonroy",
      label: "Bonroy",
    },
    {
      value: "Crescent_pk",
      label: "Crescent_pk",
    },
    {
      value: "Denoho",
      label: "Denoho",
    },
    {
      value: "Dynamic",
      label: "Dynamic",
    },
    {
      value: "Enva",
      label: "Enva",
    },
    {
      value: "Four_seasons",
      label: "Four_seasons",
    },
    {
      value: "Ideal",
      label: "Ideal",
    },
    {
      value: "Infiiloom",
      label: "Infiiloom",
    },
    {
      value: "Interloop",
      label: "Interloop",
    },
    {
      value: "Kamal",
      label: "Kamal",
    },
    {
      value: "MSL",
      label: "MSL",
    },
    {
      value: "Northeast",
      label: "Northeast",
    },
    {
      value: "Ou_wei",
      label: "Ou_wei",
    },
    {
      value: "Premium",
      label: "Premium",
    },
    {
      value: "Rong_xing",
      label: "Rong_xing",
    },
    {
      value: "Royce",
      label: "Royce",
    },
    {
      value: "Slane",
      label: "Slane",
    },
    {
      value: "Super",
      label: "Super",
    },
    {
      value: "Talent",
      label: "Talent",
    },
    {
      value: "Tauseef",
      label: "Tauseef",
    },
    {
      value: "Walt",
      label: "Walt",
    },
    {
      value: "Yiheng",
      label: "Yiheng",
    },
  ];
  
  const handleChange = (value) => {
    setBrandId(value);
  };
  const onDateChange = (dates, dateString) => {
    setSelectedRange(dateString);
    setOnSubmitClearDate(dates);
  };

  const onSeasonChange = (value) => {
    setSeasonFilter(value)
  };

  const onSubmitClick = () => {
    if (!seasonFilter) {
      toast.error("!Please select season");
    }
    else if (!brandId) {
      toast.error("!Please select Vendor");
    } 
    // else if (selectedRange.length === 0) {
    //   toast.error("!Please select Date range");
    // } 
    else {
      dispatch(
        srtReportGeneration(brandId, selectedRange[0] ? selectedRange[0] : "", selectedRange[1] ? selectedRange[1] : "", seasonFilter)
      );
      setBrandId();
      setSeasonFilter();
      setSelectedRange([]);
      setOnSubmitClearDate([]);
    }
  };
  const handleKeyDown = (event) => {
    console.log("keyevent", event);
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Regular expression to match only numeric characters and allowed keys
    const allowedKeys = [
      "Tab",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
    ];
    const numericRegex = /^[0-9]*$/;

    if (!numericRegex.test(keyValue) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };
  useEffect(() => {
    if (styleListData && Array?.isArray(styleListData)) {
      const brandArray = styleListData?.map((item) => item?.brand);
      setBrandOptions([...new Set(brandArray)]);
    }
  }, [styleListData]);

  return (
    <div className="srt-main-wrapper">
      <div className="report-generation-top-wrapper">
        <p className="report-generation-label">Report Generation</p>
        <div className="report-generation-top-container">
          <div className="dickies-drop-down-one">
            <DropDown
              allowClear
              onChange={onSeasonChange}
              placeholder={"Season"}
              options={seasons}
              value={seasonFilter}
            />
          </div>
          <div className="srt-first-drop-down">
            <DropDown
              allowClear
              placeholder={"Vendor"}
              options={option}
              value={brandId}
              onChange={handleChange}
            />
          </div>
          <div className="range-picker-container">
            <RangePicker
              onKeyDown={handleKeyDown}
              allowClear={false}
              format={"MM/DD/YYYY"}
              onChange={onDateChange}
              value={onSubmitClearDate}
            />
          </div>
          <Button
            onClick={onSubmitClick}
            className="submit-buttom"
            children={"SUBMIT"}
          />
        </div>
      </div>
      <SrtTabs />
    </div>
  );
};

export default Srt;
