import React from "react";
import "./StatusUpdate.css";
import Header from "../Header/Header";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { default as Select } from "../../UIComponents/DropDown/DropDown";
import {
  dashboardStyleList,
  emailStatusUpdate,
} from "../../Redux/actions/Dashboard/Dashboard";
import { srfTableSearch } from "../../Redux/actions/Srf/Srf";
import { Collapse, Checkbox, Table } from "antd";
const { Panel } = Collapse;

const columns = [
  {
    title: "Request",
    dataIndex: "request",
    key: "request",
    width: 20,
  },
  {
    title: "Color Code",
    dataIndex: "colorcode",
    key: "colorcode",
    width: 20,
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
    width: 20,
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: 40,
  },
  {
    title: "DROA Required",
    dataIndex: "droarequired",
    key: "droarequired",
    width: 20,
  },
  {
    title: "DROA Recieved",
    dataIndex: "droarecieved",
    key: "droarecieved",
    width: 2,
  },
];

const StatusUpdate = () => {
  const dispatch = useDispatch();
  const emailDataFromStorage = JSON.parse(
    localStorage.getItem("dataForEmailStatus")
  );
  const styleListData = useSelector((state) => state?.Dashboard?.styleList);
  const emailStatusData = useSelector((state) => state?.Dashboard?.emailStatus);
  console.log("stylelist from emailStatusData", emailStatusData);
  const [style, setStyle] = useState();
  const [factory, setFactory] = useState();
  const [singleFilteredStyle, setSingleFilteredStyle] = useState([]);
  const [singleFilteredFactory, setSingleFilteredFactory] = useState([]);
  const [roaReceived, setRoaReceived] = useState();
  const [isRoaModalOpen, setisRoaModalOpen] = useState(false);
  const [dataFotDROA, setDataForDROA] = useState({});

  const dataSource = [];
  const colors = [{ color: "001", size: ["M", "L"], quantity: ["10", "12"] }];
  {
    emailStatusData?.forEach((item, index) => {
      const requests = item?.requests?.map((data) => (
        <p key={data?.reqno} className="popup-quantity">
          {data?.reqno}
        </p>
      ));

      const colorcode = colors.map((data) => (
        <p key={data?.color} className="popup-quantity">
          {data?.color}
        </p>
      ));

      const sizeRows = colors[0]?.size.map((size, sizeIndex) => (
        <div key={sizeIndex}>
          <p className="popup-quantity">{size}</p>
        </div>
      ));

      const quantityRows = colors[0]?.quantity.map(
        (quantity, quantityIndex) => (
          <div key={quantityIndex}>
            <p className="popup-quantity">{quantity}</p>
          </div>
        )
      );

      dataSource.push({
        key: index,
        request: requests,
        colorcode: colorcode,
        size: sizeRows,
        quantity: quantityRows,
      });
    });
  }

  const onStyleChange = (value) => {
    setStyle(value);
  };
  const onFactoryChange = (value) => {
    setFactory(value);
  };
  useEffect(() => {
    dispatch(dashboardStyleList());
    dispatch(
      emailStatusUpdate(
        emailDataFromStorage?.style,
        emailDataFromStorage?.vendor
      )
    );
  }, []);
  useEffect(() => {
    if (style) {
      dispatch(srfTableSearch("", style, factory, ""));
      localStorage.setItem(
        "SrfSearchData",
        JSON.stringify({
          brand: "",
          style: style,
          factory: factory,
          season: "",
        })
      );
      localStorage.setItem(
        "DashboardData",
        JSON.stringify({
          brand: "",
          style: style,
          factory: factory,
          season: "",
        })
      );
      setFactory();
      setStyle();
    } else {
      // toast.error("Please Select Style");
    }
  }, [factory]);
  useEffect(() => {
    if (styleListData && Array?.isArray(styleListData)) {
      const filterUsingStyle = styleListData?.map((item) => item.style);
      console.log("style in statusupdate", filterUsingStyle);
      setSingleFilteredStyle(filterUsingStyle);
      if (style) {
        const filterUsingStyle = styleListData?.filter(
          (item) => item.style === style
        );
        const finalFactoryList = filterUsingStyle?.map((item) => item?.factory);
        console.log("finalFactoryList", filterUsingStyle);

        const uniqueNames = new Set();
        finalFactoryList.forEach((names) => {
          if (Array.isArray(names)) {
            names.forEach((name) => {
              uniqueNames.add(name);
            });
          } else {
            uniqueNames.add(names);
          }
        });
        const uniqueArray = Array.from(uniqueNames);
        setSingleFilteredFactory(finalFactoryList ? uniqueArray : "");
      } else if (style) {
        const filterUsingStyle = styleListData?.filter(
          (item) => item.style === style
        );
        const finalFactoryList = filterUsingStyle?.map((item) => item?.factory);
        console.log("finalFactoryList", filterUsingStyle);

        const uniqueNames = new Set();
        finalFactoryList.forEach((names) => {
          if (Array.isArray(names)) {
            names.forEach((name) => {
              uniqueNames.add(name);
            });
          } else {
            uniqueNames.add(names);
          }
        });
        const uniqueArray = Array.from(uniqueNames);
        setSingleFilteredFactory(finalFactoryList ? uniqueArray : "");
      }
      if (factory) {
        const arrayData = styleListData
          ?.map((item, index) => {
            console.log("here is data", item, index);
            const itemData = item?.factory?.map((reqData) => {
              console.log("reqDatareqData", reqData);
              return {
                ...item,
                factory: reqData,
              };
            });
            return itemData;
          })
          .flat();
      }
    }
  }, [styleListData, style, factory]);
  const onChange = (key) => {
    console.log(key);
  };
  const onRoaReceived = (e, index, data) => {
    setDataForDROA(data);
    if (e.target.checked == true) {
      setRoaReceived(1);
      setisRoaModalOpen(true);
    } else if (e.target.checked == false) {
      setRoaReceived(0);
      setisRoaModalOpen(true);
    } else {
      setRoaReceived(0);
      setisRoaModalOpen(false);
    }
  };
  return (
    <div>
      <Header />
      <h3 className="status-title">Status Update</h3>
      <div className="status-container">
        <div className="status-dropdowns">
          <div className="srf-dropdown-width srf-drop-down-one">
            <Select
              showSearch
              placeholder={"Style"}
              allowClear
              value={style}
              data={singleFilteredStyle}
              onChange={onStyleChange}
            ></Select>
          </div>
          <div className="srf-dropdown-width srf-drop-down-one">
            <Select
              placeholder={"Factory"}
              allowClear
              value={factory}
              data={singleFilteredFactory}
              onChange={onFactoryChange}
            ></Select>
          </div>
        </div>
      </div>

      {emailStatusData?.map((item, index) => {
        console.log("email map data", item);
        return (
          <div className="status-collapse">
            <Collapse defaultActiveKey={["1"]} onChange={onChange}>
              <Panel
                header={
                  <div className="status-header-text">
                    <div className="vendor-style-text">
                      <span>{item?.style}</span> <span>{item?.vendor}</span>
                    </div>
                    <div className="status-checkbox">
                      <Checkbox
                        // checked={item?.roa_received}
                        onChange={(e) => onRoaReceived(e, index)}
                      >
                        Is ROA received?
                      </Checkbox>
                      <Checkbox
                        // checked={item?.roa_received}
                        onChange={(e) => onRoaReceived(e, index)}
                      >
                        Is ROA needed?
                      </Checkbox>
                    </div>
                  </div>
                }
                key="1"
              >
                <div>
                  <Table
                    // pagination={true}
                    pagination={{ pageSize: 100 }}
                    dataSource={dataSource}
                    columns={columns}
                    rowClassName="editable-row"
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};

export default StatusUpdate;
