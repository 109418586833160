import React, { useState } from "react";
import InputField from "../../../UIComponents/Input/Input";

const RuInput = ({ ruData, onChange, value, onClick ,className,disabled,type }) => {
  return (
    <div className="srt-table-data-three">
    <div className="srt-date-pickers">
      <InputField onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, "").slice(0, 2);
          }} className={className} pattern="[1-9]" type='text' onClick={onClick} value={value} onChange={onChange} maxLength="3" disabled={disabled} />
    </div>
    </div>
  );
};

export default RuInput;
