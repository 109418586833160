import React from "react";
/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
import "../Header/Header.css"

export const ProfileData = (props) => {
    return (
        <div id="profile-div">
            <p><strong><span className="profile-title-name">Name</span><span>:</span></strong> {props?.graphData?.givenName} {props?.graphData?.surname}</p>
            <p><strong><span className="profile-title-email">Email</span><span>:</span></strong> {props?.graphData?.userPrincipalName}</p>
            {/* <p><strong>Id: </strong> {props?.graphData?.id}</p> */}
        </div>
    );
};