import React, { useState } from "react";
import { Modal, Button, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import TickLogo from "../../../../images/tick.png";
import CrossLogo from "../../../../images/cross.png";
import ChatLogo from "../../../../images/chat.png";
import "./PD.css";
import CustomDropDown from "./PdDropDown";
import EmptyChat from "../../../../images/EmptyChat.png";
import {
  CommentUpdate,
  brandCommentSaver,
} from "../../../../Redux/actions/Srt/Srt";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { toast } from "../../../../UIComponents/Toast";

const PD = ({
  data,
  pdStatus,
  commentReciever,
  reqNo,
  dataOfRow,
  statusFor,
  user,
}) => {  
  const [mappedData, setMappedData] = useState([])
  useEffect(() => {
    if (data?.pd_cmt && Array.isArray(data.pd_cmt)) {
      const mapData = data.pd_cmt.reduce((acc, item) => {
        const existingUserIndex = acc.findIndex(user => user.username === item.username);
  
        if (existingUserIndex !== -1) {
          acc[existingUserIndex].comments.push(item.comments);
          acc[existingUserIndex].last_comment = item.comments;
          acc[existingUserIndex].last_id = item.id;
          acc[existingUserIndex].updated_at = item.updated_at;
          acc[existingUserIndex].comment_count = item.comment_count;
        } else {
          acc.push({
            username: item.username,
            comments: [item.comments],
            last_comment: item.comments,
            last_id: item.id,
            updated_at: item.updated_at,
            comment_count: item.comment_count
          });
        }
  
        return acc;
      }, []);
      setMappedData(mapData);
    } else {
      setMappedData([]);
    }
  }, [data]);

console.log("usertestFilter",mappedData);

  const dispatch = useDispatch();
  const profileDetailData = useSelector(
    (state) => state?.Profile?.profileDetails?.role
  );
  console.log("profileDetailData", profileDetailData);
  const brandNameFromApi = useSelector((state) => state?.Srt?.srtBrandNames);
  const userDetails = useSelector((state) => state?.Profile?.profileDetails);
  const userNameMicrosoft = useSelector(
    (state) => state?.Profile?.graphCompleteData?.displayName
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [reqNumber, setReqNumber] = useState("");
  const [TooltipInputText, setTooltipInputText] = useState("");
  const [commentUpdateReqData, setCommentUpdateReqData] = useState({});
  const [commentData, setCommentData] = useState();
  const [indexValue, setIndexValue] = useState("");
  const [commentDataModified, setCommentDataModified] = useState();
  const [onEditBtnClicked, setOnEditBtnClicked] = useState(false);
  const [hoverClick, setHoverClick]  = useState("hover")
  const [comment_count, setCommentCount] = useState();
  const [userExists, setUserExists] = useState();

  const toolTipCommentsData = () => {
    return {
      style: dataOfRow?.style,
      brand: dataOfRow?.brand,
      factory: dataOfRow?.factory,
      season: dataOfRow?.season,
      color_code: dataOfRow?.color_code,
      request_no: reqNumber,
      size: commentUpdateReqData?.size,
      comments: TooltipInputText,
      username: userNameMicrosoft,
      role_name: userDetails?.role,
      comment_count,
      comment_type: "PD",
    };
  };

  const toolTipCommentsUpdateData = (id,count) => {
    return {
      style: dataOfRow?.style,
      brand: dataOfRow?.brand,
      factory: dataOfRow?.factory,
      season: dataOfRow?.season,
      color_code: dataOfRow?.color_code,
      request_no: reqNumber,
      size: commentUpdateReqData?.size,
      comments: TooltipInputText,
      username: userNameMicrosoft,
      role_name: userDetails?.role,
      comment_count: count,
      id: id,
    };
  };
  
  const [filteredUserName, setFilteredUserName] = useState([]);

  console.log("TooltipInputText",TooltipInputText);
  const onCommentEdit = (item, index, requestNo, dataOfRow) => {
    console.log("clicked",item, index, requestNo, dataOfRow);
    setTooltipInputText(item?.last_comment);
    setCommentCount(item?.comment_count)
    setIndexValue(index);
    setReqNumber(requestNo);
    setCommentUpdateReqData(dataOfRow);
    setOnEditBtnClicked(true);
  };

  const userName = [];
  useEffect(() => {
    if (data?.pd_cmt && Array?.isArray(data?.pd_cmt)) {
      data?.pd_cmt?.map((item, index) => {
        userName.push(item?.username);
        if (userName && Array?.isArray(userName)) {
          const filteredUser = userName.filter(
            (name) => name === userNameMicrosoft
          );
          setFilteredUserName(filteredUser);
        }
      });
    }
  }, [data]);

  const ontooltipInputTextChange = (e) => {
    setTooltipInputText(e.target.value);
  };

  const onTickClick = (id,count) => {
    if (!TooltipInputText) {
      toast.error("Please add the comment");
    } else if (
      (profileDetailData !== "Admin" || profileDetailData !== "Brand")
    ) {
      dispatch(CommentUpdate(toolTipCommentsUpdateData(id,count), brandNameFromApi));
    }
  };
  const onCloseClick = () => {
    setOnEditBtnClicked(false);
  };

  const onBrandEmptyCommentClick = (count, requestNo, dataOfRow) => {
    setCommentCount(count)
    setReqNumber(requestNo);
    setTooltipInputText("")
    setCommentUpdateReqData(dataOfRow);
    setIsModalOpen(true);
  };

  const onBrandCommentClick = (count, requestNo, dataOfRow) => {
    console.log("here is data", requestNo, dataOfRow);
    setCommentCount(count + 1)
    setReqNumber(requestNo);
    setTooltipInputText("")
    setCommentUpdateReqData(dataOfRow);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (mappedData) {
      const User = mappedData.some(item => item.username.includes(userNameMicrosoft));
      setUserExists(User)
    }
  },[mappedData])

  const comment =
    data?.pd_cmt && Array?.isArray(data?.pd_cmt) ? (
      <>
        <p className="tooltip-comment">
          <b>Comment</b>
        </p>
        {mappedData?.map((item, index) => {
          return (
            <>
              <div key={item.last_id} className="tooltip-name-date-container">
                <b className="tooltip-comment">
                  {item?.username}
                </b>
                <p className="tooltip-comment-date-time">{item?.updated_at}</p>
              </div>
              {onEditBtnClicked && item?.username === userNameMicrosoft ?
                  <>
                  <TextArea
                    value={TooltipInputText}
                    rows={2}
                    onChange={ontooltipInputTextChange}
                  />
                  <div className="tick-cross-container">
                    <img
                      className="tick-and-cross"
                      onClick={() => onTickClick(item?.last_id,item?.comment_count)}
                      src={TickLogo}
                      alt="tick"
                    />
                    <img
                      className="tick-and-cross"
                      onClick={onCloseClick}
                      src={CrossLogo}
                      alt="cross"
                    />
                  </div>
                </> :
                <>
                {item.comments.map((comment, index) => (
                  <div key={index}>
                    {comment.split(/(?<=\n)(?=[a-zA-Z])/).map((line, lineIndex) => (
                      <p key={lineIndex}className="tooltip-comment">
                         {lineIndex === 0 ? `${index + 1}. ${line}` : line}
                      </p>
                    ))}
                  </div>
                ))}
                  {item?.username == userNameMicrosoft ? (
                    <div className="edit-add-container">
                      <div className="edit-icon-container">
                      <EditOutlined
                        onClick={() =>
                          onCommentEdit(item, index, reqNo, dataOfRow)
                        }
                      />
                      </div>
                      <div>
                      <PlusOutlined
                      className="comment-edit"
                      onClick={() => onBrandCommentClick(item?.comment_count, reqNo, dataOfRow)}
                    />
                    </div>
                      </div>
                  ) : (
                    <></>
                  )}
                
                  </>
              }
            </>
          )
        })}
        {
                    !userExists ? (
                      <div className="add-button-container">
                        <div>
                          <PlusOutlined
                          className="comment-edit"
                          onClick={() => onBrandEmptyCommentClick(0, reqNo, dataOfRow)}
                          />
                        </div>
                      </div>
                    ) : <></>
                  }
      </>
    ) : (
      <></>
    );
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  const onSaveHandle = () => {
    if (!TooltipInputText) {
      toast.error("Please add the comment");
    } else if (
      (profileDetailData !== "Admin" || profileDetailData !== "Brand")
    ) {
      dispatch(brandCommentSaver(toolTipCommentsData(), brandNameFromApi));
      setIsModalOpen(false);
    }
  };
  useEffect(() => {
    if (data?.pd_cmt && Array?.isArray(data?.pd_cmt)) {
      data?.pd_cmt?.map((item) => {
        setCommentData(item);
      });
    }
  }, [data]);

  const onTooltipOpen = (value) => {
    if (value === true) {
      setHoverClick("click")
    }
    else {
      setHoverClick("hover")
    }
  }

  return (
    <div className="srt-table-data-five">
      <div className="dropdown-chatlogo-data-five">
        <div className="srt-dropdown-common">
          <CustomDropDown
            requestNo={reqNo}
            rowData={dataOfRow}
            data={data}
            statusForBrand={statusFor}
            defaultValue={pdStatus}
          />
        </div>
          {commentData?.comments == " " ||
          commentData?.comments == "" ||
          commentData?.comments == null ||
          commentData?.comments == undefined ? (
            <img
            className="comment-logo"
            onClick={() => onBrandEmptyCommentClick(0, reqNo, dataOfRow)}
            src={EmptyChat}
            alt="chatlogo"
          />
          ) : (
            <Tooltip
            onOpenChange={onTooltipOpen}
            trigger={hoverClick}
            overlayInnerStyle={{
              width: "fit-content",
              height: "fit-content",
              padding: "20px",
              color: "black",
            }}
            color={"white"}
            placement="right"
            title={comment}
          >
            <img
              className="comment-logo"
              // onClick={() => onBrandCommentClick(reqNo, dataOfRow)}
              src={ChatLogo}
              alt="chatlogo"
            />
          </Tooltip>
          )}
      </div>
        <Modal title="Comment" open={isModalOpen} onCancel={handleCancel} okText={"save"}>
          <TextArea
            value={TooltipInputText}
            rows={4}
            onChange={ontooltipInputTextChange}
          />
          <div className="srt-buttons">
            <Button className="modal-cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="modal-yes-button" onClick={onSaveHandle}>
              Save
            </Button>
          </div>
        </Modal>
    </div>
  )
}

export default PD;