import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { loginRequest } from "../authConfig";

import Cookies from "js-cookie";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASEURL}api/v1/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export { client };

const AxiosInterceptor = ({ children }) => {
  const { instance, accounts } = useMsal();

  client.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          })
          .then((response) => {
            Cookies.set("access_token", response?.accessToken);
          })
          .catch((error) => {});
      }
      const originalRequest = error.config;
      const retryVar = error.config._retry;
      if (error.response.status === 401 && !originalRequest._retry) {
        let secure_token = Cookies.get("access_token");
        originalRequest._retry = true;
        originalRequest.headers["Authorization"] = `Bearer ${secure_token}`;

        return client(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  return children;
};

export default AxiosInterceptor;
