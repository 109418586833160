import React from 'react';
import { Button } from 'antd';

function CommonButton({children,className,onClick,disabled}) {
  return (
    <div>
      <Button disabled={disabled} onClick={onClick} className={className}>{children}</Button>
    </div>
  )
}

export default CommonButton