import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CommentUpdate,
  brandCommentSaver,
  designStatusSaver,
} from "../../../../Redux/actions/Srt/Srt";
import "../../../../UIComponents/CustomDropDown/CustomDropDown.css";
import { Button, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useMsal } from "@azure/msal-react";

const CustomDropDown = ({
  data,
  defaultValue,
  requestNo,
  rowData,
  statusForBrand,
  disabled,
}) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [TooltipInputText, setTooltipInputText] = useState("");
  const [commentUpdateReqData, setCommentUpdateReqData] = useState({});
  const profileDetailData = useSelector(
    (state) => state?.Profile?.profileDetails?.role
  );
  const userDetails = useSelector((state) => state?.Profile?.profileDetails);
  const userNameMicrosoft = useSelector(
    (state) => state?.Profile?.graphCompleteData?.displayName
  );
  const brandNameFromApi = useSelector((state) => state?.Srt?.srtBrandNames);
  console.log("userlog", userDetails);

  const { instance, accounts } = useMsal();
  const [filteredUserName, setFilteredUserName] = useState("");
  const [filteredUserId, setFilteredUserId] = useState("");
  const [overrideStatus, setOverideStatus] = useState("");
  const [dropDownValue, setDropDownValue] = useState(defaultValue || "0");
  const [comment_count, setCommentCount] = useState("")

  useEffect(() => {
    if (data?.pd_cmt && Array?.isArray(data?.pd_cmt)) {
      data?.pd_cmt?.map((item, index) => {
        if (item?.username === userNameMicrosoft) {
          console.log("itemid", item);
          setFilteredUserName(item?.username);
          setFilteredUserId(item?.id);
          setTooltipInputText(item?.comments)
          setCommentCount(item?.comment_count)
        }
      });
    }
  }, [data]);

  const toolTipCommentsData = () => {
    return {
      style: rowData?.style,
      brand: rowData?.brand,
      factory: rowData?.factory,
      season: rowData?.season,
      color_code: rowData?.color_code,
      request_no: requestNo,
      size: commentUpdateReqData?.size,
      comments: TooltipInputText,
      username: userNameMicrosoft,
      role_name: userDetails?.role,
      comment_type: "PD",
      comment_count: 0
    };
  };

  const toolTipCommentsUpdateData = () => {
    return {
      style: rowData?.style,
      brand: rowData?.brand,
      factory: rowData?.factory,
      season: rowData?.season,
      color_code: rowData?.color_code,
      request_no: requestNo,
      size: commentUpdateReqData?.size,
      comments: TooltipInputText,
      username: userNameMicrosoft,
      role_name: userDetails?.role,
      comment_count,
      id: filteredUserId,
    };
  };

  const onDropChange = (e, requestNo, rowData, statusForBrand) => {
    setCommentUpdateReqData(rowData);
    let item = e.target.options[e.target.selectedIndex];
    let color = item.getAttribute("color");
    e.target.style.backgroundColor = color;
    if (data?.Qty == 0) {
      setDropDownValue(e.target.value);
    }
    if (e.target.value === "Override") {
      setOverideStatus(e.target.value);
    }
    if (e.target.value === "Rejected" || e.target.value === "Override") {
      setIsModalOpen(true);
    } else if (profileDetailData !== "Admin" || profileDetailData !== "PD") {
      setDropDownValue(e.target.value);
      setOverideStatus();
      dispatch(
        designStatusSaver(
          rowData?.style,
          rowData?.brand,
          rowData?.factory,
          rowData?.size,
          rowData?.season,
          rowData?.color_code,
          requestNo,
          "",
          "",
          e.target.value,
          ""
        )
      );
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onSaveHandle = () => {
    if (!TooltipInputText) {
      toast.error("Please add the comment");
    } else if (
      (profileDetailData !== "Admin" || profileDetailData !== "PD") &&
      filteredUserName === userNameMicrosoft
    ) {
      setIsModalOpen(false);
      if (overrideStatus) {
        setDropDownValue("Override");
        dispatch(
          designStatusSaver(
            rowData?.style,
            rowData?.brand,
            rowData?.factory,
            rowData?.size,
            rowData?.season,
            rowData?.color_code,
            requestNo,
            "",
            "",
            "Override",
            ""
          )
        );
        dispatch(CommentUpdate(toolTipCommentsUpdateData(), brandNameFromApi));
        setOverideStatus();
      } else {
        setDropDownValue("Rejected");
        dispatch(
          designStatusSaver(
            rowData?.style,
            rowData?.brand,
            rowData?.factory,
            rowData?.size,
            rowData?.season,
            rowData?.color_code,
            requestNo,
            "",
            "",
            "Rejected",
            ""
          )
        );
        dispatch(CommentUpdate(toolTipCommentsUpdateData(), brandNameFromApi));
      }
    } else {
      setIsModalOpen(false);
      if (overrideStatus) {
        setDropDownValue("Override");
        dispatch(
          designStatusSaver(
            rowData?.style,
            rowData?.brand,
            rowData?.factory,
            rowData?.size,
            rowData?.season,
            rowData?.color_code,
            requestNo,
            "",
            "",
            "Override",
            ""
          )
        );
        dispatch(brandCommentSaver(toolTipCommentsData(), brandNameFromApi));
        setOverideStatus();
      } else {
        dispatch(
          designStatusSaver(
            rowData?.style,
            rowData?.brand,
            rowData?.factory,
            rowData?.size,
            rowData?.season,
            rowData?.color_code,
            requestNo,
            "",
            "",
            "Rejected",
            ""
          )
        );
        dispatch(brandCommentSaver(toolTipCommentsData(), brandNameFromApi));
      }
    }
  };
  const ontooltipInputTextChange = (e) => {
    setTooltipInputText(e.target.value);
  };
  return (
    <div>
      <div className="custom-drop-down">
        <select
          disabled={disabled}
          onChange={(e) => onDropChange(e, requestNo, rowData)}
          id="drop"
          style={{
            backgroundColor:
              dropDownValue == "Approved"
                ? "#96CFBE"
                : dropDownValue == "Override"
                ? "#f4e3b5"
                : dropDownValue == "Rejected"
                ? "#f8ccd4"
                : dropDownValue == "Incomplete"
                ? "#85ceff"
                : dropDownValue == "N/A"
                ? "#e8e9ee"
                : "#e6f4ff",
          }}
          defaultValue={defaultValue}
          value={dropDownValue}
        >
          <option
            style={{ backgroundColor: "#e6f4ff" }}
            color="#e6f4ff"
            value="0"
          >
            -
          </option>
          <option
            style={{ backgroundColor: "#96CFBE" }}
            color={
              dropDownValue == "Approved"
                ? "#96CFBE"
                : dropDownValue == "Override"
                ? "#f4e3b5"
                : dropDownValue == "Rejected"
                ? "#f8ccd4"
                : dropDownValue == "Incomplete"
                ? "#85ceff"
                : dropDownValue == "N/A"
                ? "#e8e9ee"
                : "#e6f4ff"
            }
            value="Approved"
            name="Approved"
          >
            Approved
          </option>
          <option
            style={{ backgroundColor: "#f4e3b5" }}
            color={
              dropDownValue == "Approved"
                ? "#96CFBE"
                : dropDownValue == "Override"
                ? "#f4e3b5"
                : dropDownValue == "Rejected"
                ? "#f8ccd4"
                : dropDownValue == "Incomplete"
                ? "#85ceff"
                : dropDownValue == "N/A"
                ? "#e8e9ee"
                : "#e6f4ff"
            }
            value="Override"
            name="Override"
          >
            Override
          </option>
          <option
            style={{ backgroundColor: "#85ceff" }}
            color={
              dropDownValue == "Approved"
                ? "#96CFBE"
                : dropDownValue == "Override"
                ? "#f4e3b5"
                : dropDownValue == "Rejected"
                ? "#f8ccd4"
                : dropDownValue == "Incomplete"
                ? "#85ceff"
                : dropDownValue == "N/A"
                ? "#e8e9ee"
                : "#e6f4ff"
            }
            value="Incomplete"
            name="Incomplete"
          >
            Incomplete
          </option>
          <option
            style={{ backgroundColor: "#f8ccd4" }}
            color={
              dropDownValue == "Approved"
                ? "#96CFBE"
                : dropDownValue == "Override"
                ? "#f4e3b5"
                : dropDownValue == "Rejected"
                ? "#f8ccd4"
                : dropDownValue == "Incomplete"
                ? "#85ceff"
                : dropDownValue == "N/A"
                ? "#e8e9ee"
                : "#e6f4ff"
            }
            value="Rejected"
            name="Rejected"
          >
            Rejected
          </option>
          <option
            style={{ backgroundColor: "#e8e9ee" }}
            color={
              dropDownValue == "Approved"
                ? "#96CFBE"
                : dropDownValue == "Override"
                ? "#f4e3b5"
                : dropDownValue == "Rejected"
                ? "#f8ccd4"
                : dropDownValue == "Incomplete"
                ? "#85ceff"
                : dropDownValue == "N/A"
                ? "#e8e9ee"
                : "#e6f4ff"
            }
            value="N/A"
            name="N/A"
          >
            N/A
          </option>
        </select>
      </div>
      <Modal title="Comment" open={isModalOpen} onCancel={handleCancel} okText={"save"}>
        <TextArea
          value={TooltipInputText}
          rows={4}
          onChange={ontooltipInputTextChange}
        />
        <div className="srt-buttons">
          <Button className="modal-cancel-btn" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="modal-yes-button" onClick={onSaveHandle}>
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CustomDropDown;
