import * as types from "../../actionType";

const initialState = {
  changeTabs: "false.1",
  styleList: [],
  styleListSearch: [],
  styleListSearchFilter: [],
  statusSaver: {},
  dashboardSingleRowData: {},
  styleDetailsData: {},
  loadingForLoader: false,
  searchLoader: false,
  listLoader: false,
  dashboardStatus: {},
  droaNeededStatus: {},
  droaCheckedStatus: {},
  roaStatus: {},
  dashboardReport: {},
  emailStatus: [],
  expandRowkey: null,
  tpDropDownStatusResp: {},
  savedEmailData: {},
  saveEmail: {},
  deleteEmail: {},
  sendEmailUpdatedStatus: {},
  downloadEmailDetails: {},
  sendEmail: {},
  closeModalEmail: false,
};

export const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANING_TABS_WITH_CUSTOM_BUTTON:
      return {
        ...state,
        changeTabs: action.payload,
        loading: false,
      };
    case types.RESET_DASHBOARD_DATA:
      return {
        ...state,
        styleList: action.payload,
        loading: false,
      };
    case types.RESET_DASHBOARD_SEARCH:
      return {
        ...state,
        styleListSearch: action.payload,
        loading: false,
      };
    case types.RESET_DASHBOARD_STYLE_DATA:
      return {
        ...state,
        styleDetailsData: action.payload,
        loading: false,
      };
    case types.CLOSE_EMAIL_MODAL:
      return {
        ...state,
        closeModalEmail: action.payload,
        loading: false,
      };
    case types.DROA_CHECKED_VARIABLE:
      return {
        ...state,
        droaCheckedStatus: action.payload,
        loading: false,
      };
    case types.STYLE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        listLoader: true,
      };
    case types.STYLE_LIST_SUCCESS:
      return {
        ...state,
        styleList: action.payload,
        loading: false,
        listLoader: false,
      };
    case types.STYLE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        styleList: [],
        listLoader: false,
      };
    case types.STYLE_LIST_SEARCH_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
      };
    case types.STYLE_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        styleListSearch: action.payload,
        loading: false,
        searchLoader: false,
      };
    case types.STYLE_LIST_SEARCH_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        styleListSearch: [],
        searchLoader: false,
      };
    case types.STYLE_LIST_SEARCH_FILTER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.STYLE_LIST_SEARCH_FILTER_SUCCESS:
      return {
        ...state,
        styleListSearchFilter: action.payload,
        loading: false,
      };
    case types.STYLE_LIST_SEARCH_FILTER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        styleListSearchFilter: [],
      };
    case types.STATUS_SAVER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.STATUS_SAVER_SUCCESS:
      return {
        ...state,
        statusSaver: action.payload,
        loading: false,
      };
    case types.STATUS_SAVER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        statusSaver: {},
      };
    case types.DASHBOARD_SINGLE_ROW_DATA:
      return {
        ...state,
        dashboardSingleRowData: action.payload,
        loading: false,
      };
    case types.STYLE_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        loadingForLoader: true,
      };
    case types.STYLE_DETAILS_SUCCESS:
      return {
        ...state,
        styleDetailsData: action.payload,
        dashboardStatus: action.payload,
        loading: false,
        loadingForLoader: false,
      };
    case types.STYLE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loadingForLoader: false,
        styleDetailsData: {},
      };
    case types.DASHBOARD_STATUS_UPDATE:
      return {
        ...state,
        dashboardStatus: action.payload,
        loading: false,
      };
    case types.DROA_NEEDED_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.DROA_NEEDED_SUCCESS:
      return {
        ...state,
        droaNeededStatus: action.payload,
        loading: false,
      };
    case types.DROA_NEEDED_FAIL:
      return {
        ...state,
        droaNeededStatus: {},
        error: action.payload,
      };
    case types.ROA_RECEIVED_STATUS_LOADING:
      return {
        ...state,
        loading: true,
        loadingForLoader: true,
      };
    case types.ROA_RECEIVED_STATUS_SUCCESS:
      return {
        ...state,
        roaStatus: action.payload,
        loading: false,
        loadingForLoader: false,
      };
    case types.ROA_RECEIVED_STATUS_FAIL:
      return {
        ...state,
        roaStatus: {},
        error: action.payload,
        loadingForLoader: false,
      };
    case types.DASHBOARD_REPORT_GENERATION_LOADING:
      return {
        ...state,
        loading: true,
        loadingForLoader: true,
      };
    case types.DASHBOARD_REPORT_GENERATION_SUCCESS:
      return {
        ...state,
        dashboardReport: action.payload,
        loading: false,
        loadingForLoader: false,
      };
    case types.DASHBOARD_REPORT_GENERATION_FAIL:
      return {
        ...state,
        dashboardReport: {},
        error: action.payload,
        loadingForLoader: false,
      };
    case types.UPDATE_SAMPLES_EMAIL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_SAMPLES_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailUpdatedStatus: action.payload,
        loading: false,
      };
    case types.UPDATE_SAMPLES_EMAIL_FAIL:
      return {
        ...state,
        sendEmailUpdatedStatus: {},
        error: action.payload,
      };
    case types.EMAIL_STATUS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.EMAIL_STATUS_SUCCESS:
      return {
        ...state,
        emailStatus: action.payload,
        loading: false,
      };
    case types.EMAIL_STATUS_FAIL:
      return {
        ...state,
        emailStatus: {},
        error: action.payload,
      };
    case types.SEND_EMAIL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmail: action.payload,
        loading: false,
      };
    case types.SEND_EMAIL_FAIL:
      return {
        ...state,
        sendEmail: {},
        error: action.payload,
      };
    case types.SAVE_EMAIL_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SAVE_EMAIL_DETAILS_SUCCESS:
      return {
        ...state,
        saveEmail: action.payload,
        loading: false,
      };
    case types.SAVE_EMAIL_DETAILS_FAIL:
      return {
        ...state,
        saveEmail: {},
        error: action.payload,
      };
    case types.DELETE_EMAIL_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_EMAIL_DETAILS_SUCCESS:
      return {
        ...state,
        deleteEmail: action.payload,
        loading: false,
      };
    case types.DELETE_EMAIL_DETAILS_FAIL:
      return {
        ...state,
        deleteEmail: {},
        error: action.payload,
      };
    case types.DOWNLOAD_EMAIL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.DOWNLOAD_EMAIL_SUCCESS:
      return {
        ...state,
        downloadEmailDetails: action.payload,
        loading: false,
      };
    case types.DOWNLOAD_EMAIL_FAIL:
      return {
        ...state,
        downloadEmailDetails: {},
        error: action.payload,
      };
    case types.TP_STATUS_DROPDOWN_UPDATE_LOADING:
      return {
        ...state,
        loading: true,
        loadingForLoader: true,
      };
    case types.TP_STATUS_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        tpDropDownStatusResp: action.payload,
        loading: false,
        loadingForLoader: false,
      };
    case types.TP_STATUS_DROPDOWN_UPDATE_FAIL:
      return {
        ...state,
        tpDropDownStatusResp: {},
        error: action.payload,
        loadingForLoader: false,
      };
    case types.GET_SAVED_EMAIL_LOADING:
      return {
        ...state,
        loading: true,
        loadingForLoader: true,
      };
    case types.GET_SAVED_EMAIL_SUCCESS:
      return {
        ...state,
        savedEmailData: action.payload,
        loading: false,
        loadingForLoader: false,
      };
    case types.GET_SAVED_EMAIL_FAIL:
      return {
        ...state,
        savedEmailData: {},
        error: action.payload,
        loadingForLoader: false,
      };
    case types.EXPAND_ACCORDION:
      return {
        ...state,
        expandRowkey: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
