import React from 'react'
import SrtPage from '../../Components/SRT/Srt'

const Srt = () => {
  return (
    <div>
        <SrtPage />
    </div>
  )
}

export default Srt