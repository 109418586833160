import { useMsal } from "@azure/msal-react";
import Cookies from "js-cookie";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  let secure_token = Cookies.get("access_token");
  const isLogin = () => {
    if (secure_token) {
      return true;
    } else {
      return false;
    }
  };
  return isLogin() ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
