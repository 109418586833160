import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginContent from "./LoginContent";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { callMsGraph } from "./Graph";
import { useIsAuthenticated } from "@azure/msal-react";
import Cookies from "js-cookie";
import { toast } from "../../UIComponents/Toast";

const Login = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) fetchLoginData();
  }, [isAuthenticated]);

  const fetchLoginData = () => {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        const path = location.pathname;
        const srfPathName = localStorage.getItem("srfPath");
        const srtPathName = localStorage.getItem("srtPath");

        if (srfPathName && srfPathName.includes("/sample-request-form/")) {
          Cookies.set("access_token", response?.accessToken);
          localStorage.setItem("LoginToken", response?.accessToken);
          localStorage.setItem("activeKey", "2");
          navigate(srfPathName);
        } else if (
          srtPathName &&
          srtPathName.includes("/sample-receipt-tracker/")
        ) {
          Cookies.set("access_token", response?.accessToken);
          localStorage.setItem("LoginToken", response?.accessToken);
          localStorage.setItem("activeKey", "3");
          navigate(srtPathName);
          localStorage.removeItem("srtPath");
        } else {
          Cookies.set("access_token", response?.accessToken);
          localStorage.setItem("LoginToken", response?.accessToken);
          localStorage.setItem("activeKey", "1");
          // dispatch an action - store at
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        toast.error("Login Failed | Error[1001]");
        navigate("/");
      });
  };

  const handleLogin = () => {
    localStorage.removeItem("DashboardData");
    localStorage.removeItem("srtActiveKey");
    localStorage.removeItem("filteredDataForSample");
    localStorage.removeItem("activeKey");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("srtData");
    localStorage.removeItem("LoginToken");
    localStorage.removeItem("RequestSend");
    localStorage.removeItem("scrollYaxis");
    localStorage.removeItem("srtMassData");
    localStorage.removeItem("SampleNotes");
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log("error for login", e);
      toast.error("Login Failed | Error[1000]");
    });
  };

  return (
    <>
      <LoginContent>
        <AuthenticatedTemplate>
          <button disabled className="loginbtn">
            Redirecting ...
          </button>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <button onClick={handleLogin} className="loginbtn">
            Azure AD
          </button>
        </UnauthenticatedTemplate>
      </LoginContent>
    </>
  );
};

export default Login;
