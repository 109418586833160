import { Table } from 'antd';
import React, { useState } from 'react'
import ExFactoryDate from '../Table/ExFactoryDate/ExFactoryDate';
import Request from '../Table/Request/Request';
import Brand from '../Table/Brand/Brand';
import QtyReqRec from '../Table/QtyReqRec/QtyReqRec';
import DateSentReceive from "../Table/DateSentRec/DateSentRec";
import { useDispatch, useSelector } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader';
import { selectUnselectSrtTableRows, srtEXportArray } from '../../../Redux/actions/Srt/Srt';

const UnsolicitedTable = () => {
    const dispatch = useDispatch();
    let srtUnsolicitedListData = useSelector((state) => state?.Srt?.srtUnsolicitedList);
    const massFilterApiData = useSelector(
        (state) => state?.Srt?.massSearchFilterResult
    );
    const seasonFilterApiData = useSelector(
    (state) => state?.Srt?.seasonSearchFilterResult
  );
  const srtSearchLoader = useSelector((state) => state?.Srt?.searchLoader);
  const srtSeasonData = useSelector((state) => state?.Srt?.srtSeasonSearch);
  const selectedUnselectedRows = useSelector(
    (state) => state?.Srt?.srtSelectedTableRows
);
console.log("selectedUnselectedRows",selectedUnselectedRows);
  
  const pageSize = 10;
  const initialPage = localStorage.getItem("currentPage") || 1;
  const defaultCurrent = parseInt(initialPage, 10);
  const [currentPage, setCurrentPage] = useState(defaultCurrent);


  const onParentStyleClicked = () => {
    localStorage.setItem(
      "DashboardData",
      JSON.stringify({
        style: sockId,
        factory: vendor,
        season: season,
        brand: brand,
      })
    );
    dispatch(resetSrfSearchData([]));
    localStorage.setItem("RequestSend", false);
    dispatch(changingTabsWithCustomButton("true.2"));
    localStorage.removeItem("SrfSearchData");
    navigate("/sample-request-form");
  };

  const onSelectChange = (newSelectedRowKeys, record) => {
    const pidArray = [];
    const brandArray = record?.map((item) => {
      pidArray.push(item.ReferenceNumber
        .props.children);
    });
    dispatch(srtEXportArray(pidArray));
    dispatch(selectUnselectSrtTableRows(newSelectedRowKeys));
  };

  const paginationConfig = {
    pageSize,
    defaultCurrent,
    onChange: (page) => handlePageChange(page),
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page);
  };

    const intialSearch = () => {
        if (massFilterApiData && massFilterApiData?.length > 0) {
          return massFilterApiData;
        } else if (seasonFilterApiData && seasonFilterApiData?.length > 0) {
          return seasonFilterApiData;
        } else if (srtSeasonData && srtSeasonData?.length > 0) {
          return srtSeasonData;
        } else if (srtUnsolicitedListData) {
          return srtUnsolicitedListData;
        } else return [];
      };

    const columns = [
        {
          title: "Factory Name",
          dataIndex: "FactoryName",
        },
        {
          title: "COO",
          dataIndex: "COO",
        },
        {
          title: "Description",
          dataIndex: "Description",
        },
        {
          title: "Reference Number",
          dataIndex: "ReferenceNumber",
        },
        {
          title: "Color",
          dataIndex: "Color",
        },
        {
          title: "Size",
          dataIndex: "Size",
        },
        {
          title: "Date received",
          dataIndex: "DateReceived",
        },
        {
          title: "Qty.Prs. Received",
          dataIndex: "QtyPrsRequestedReceived",
        },
        {
          title: "Comments",
          dataIndex: "Comments",
        },
      ];
    const rowSelection = {
        selectedUnselectedRows,
        onChange: onSelectChange,
      };
    
      const data = [];
      {
        intialSearch()?.map((item, index) => {
            return data.push({
              key: index,
              FactoryName: (
                    <p className="vendor-name">{item?.factory_name}</p>
              ),
              COO: (
                <span className="socks-id">{item?.COO}</span>
              ),
              Description: (
                    <span className="socks-color">{item?.description}</span>
              ),
              ReferenceNumber: (
                    <span className="socks-size">{item?.reference_number}</span>
              ),
              Color: (
                <span className="socks-color">{item?.color}</span>
              ),
              Size: ( 
                <span className="socks-size">{item?.size}</span>
              ),
              DateReceived: (
                <span className="vendor-name">{item?.date_received}</span>
              ),
              QtyPrsRequestedReceived: (
                <span className="promised-ex-factory">{item?.qty_prs_received}</span>
              ),
              Comments: (
                <p className="vendor-name" style={{ width: "350px", margin: "0 auto" }}>{item?.comments}</p>
              ),
            })
        })
      }
  return (
    <div>
        {srtSearchLoader ? (
        <BeatLoader
          color={"#0C3D5E"}
          loading={srtSearchLoader}
          cssOverride={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            borderColor: "#0C3D5E",
          }}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
    ):(
        <div className="srt-table">
          <Table
            onMouseEnter={false}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            pagination={{
              ...paginationConfig,
              current: currentPage,
            }}
            rowClassName={() => "row"}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleRowClick(record, rowIndex);
                },
              };
            }}
          />
        </div>
        )}
    </div>
  )
}

export default UnsolicitedTable