import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  multiSrfSaveData,
  resetSrfList,
  srfExport,
  srfTableSearch,
} from "../../Redux/actions/Srf/Srf";
import DropDown, { default as Select } from "../../UIComponents/DropDown/DropDown";
import "./PdSampleRequestForm.css";
import {
  resetMassFilter,
  resetSeasonFilter,
} from "../../Redux/actions/Srt/Srt";
import { useNavigate } from "react-router-dom";
import { changingTabsWithCustomButton } from "../../Redux/actions/Dashboard/Dashboard";

const SrfHeader = (props, excelData, fileName) => {
  const navigate = useNavigate();
  const fileRef = useRef();
  const dispatch = useDispatch();
  const srfTableList = useSelector((state) => state?.Srf?.getSrfTableList);
  const styleListData = useSelector((state) => state?.Dashboard?.styleList);
  const srfSearchData = useSelector((state) => state?.Srf?.srfSearchData);
  const disableButtons = useSelector((state) => state?.Srf?.disableToggleSrf);
  const srfDashboardData = JSON.parse(localStorage.getItem("DashboardData"));

  const [style, setStyle] = useState();
  const [brand, setBrand] = useState();
  const [factory, setFactory] = useState();
  const [season, setSeason] = useState();
  const [formattedBrand, setFormattedBrand] = useState([]);
  const [formattedStyle, setFormattedStyle] = useState([]);
  const [formattedSeason, setFormattedSeason] = useState([]);
  const [formattedFactory, setFormattedFactory] = useState([]);
  const [formattedColor, setFormattedColor] = useState([]);
  const [singleFilteredBrand, setSingleFilteredBrand] = useState([]);
  const [singleFilteredStyle, setSingleFilteredStyle] = useState([]);
  const [singleFilteredFactory, setSingleFilteredFactory] = useState([]);
  const [singleFilteredSeason, setSingleFilteredSeason] = useState([]);

  const seasons = [
    {
      value: "F24",
      label: "F24",
    },
    {
      value: "S25",
      label: "S25",
    },
    {
      value: "F25",
      label: "F25",
    },
  ]

  console.log("formattedSeason",formattedSeason);

  const onBrandChange = (value) => {
    setBrand(value);
  };
  const onStyleChange = (value) => {
    setStyle(value);
    localStorage.setItem("SampleNotes", "");
  };
  const onFactoryChange = (value) => {
    setFactory(value);
  };
  const onSeasonChange = (value) => {
    setSeason(value);
  };

  useEffect(() => {
    if (style) {
      dispatch(srfTableSearch(brand ? brand : "", style, factory, season ? season :  ""));
      localStorage.setItem(
        "SrfSearchData",
        JSON.stringify({
          brand: brand ? brand : "",
          style: style,
          factory: factory,
          season: season ? season : "",
        })
      );
      localStorage.setItem(
        "DashboardData",
        JSON.stringify({
          brand: brand ? brand : "",
          style: style,
          factory: factory,
          season: season ? season : "",
        })
      );
      setFactory();
      setBrand();
      setStyle();
      setSeason();
      dispatch(resetSrfList());
    } else {
      // toast.error("Please Select Style");
    }
  }, [factory]);

  useEffect(() => {
    if (styleListData && Array?.isArray(styleListData)) {
      const finalBrandList = styleListData?.map((item) => item?.brand);
      const sortedUniqueBrands = Array.from(new Set(finalBrandList)).sort();
      setSingleFilteredBrand(sortedUniqueBrands);
      if (brand) {
        const filterUsingBrand = styleListData?.filter(
          (item) => item.brand === brand
        );
        const finalStyleList = filterUsingBrand?.map((item) => item?.style);
        setSingleFilteredStyle([...new Set(finalStyleList)]);
      } else {
        const filterUsingBrand = styleListData?.map((item) => item?.style);
        setSingleFilteredStyle([...new Set(filterUsingBrand)]);
      }

      if (style) {
        const filterUsingStyle = styleListData?.filter(
          (item) => item.style === style
        );
        const finalFactoryList = filterUsingStyle?.map((item) => item?.factory);
        const uniqueNames = new Set();
        finalFactoryList.forEach((names) => {
          if (Array.isArray(names)) {
            names.forEach((name) => {
              uniqueNames.add(name);
            });
          } else {
            uniqueNames.add(names);
          }
        });
        const uniqueArray = Array.from(uniqueNames);
        setSingleFilteredFactory(finalFactoryList ? uniqueArray : "");
      } else if (style) {
        const filterUsingStyle = styleListData?.filter(
          (item) => item.brand === brand && item.style === style
        );
        const finalFactoryList = filterUsingStyle?.map((item) => item?.factory);
        console.log("finalFactoryList", filterUsingStyle);

        const uniqueNames = new Set();
        finalFactoryList.forEach((names) => {
          if (Array.isArray(names)) {
            names.forEach((name) => {
              uniqueNames.add(name);
            });
          } else {
            uniqueNames.add(names);
          }
        });
        const uniqueArray = Array.from(uniqueNames);
        setSingleFilteredFactory(finalFactoryList ? uniqueArray : "");
      }

      if (factory) {
        const arrayData = styleListData
          ?.map((item, index) => {
            console.log("here is data", item, index);
            const itemData = item?.factory?.map((reqData) => {
              console.log("reqDatareqData", reqData);
              return {
                ...item,
                factory: reqData,
              };
            });
            return itemData;
          })
          .flat();

        console.log("arrayData", arrayData);
        const filterUsingFactory = arrayData?.filter(
          (item) => item.brand === brand && item.style === style
        );
        console.log("filterUsingFactory", filterUsingFactory);
        const finalSeasonList = filterUsingFactory?.map((item) => item?.season);
        setSingleFilteredSeason([...new Set(finalSeasonList)]);
      }
    }
  }, [styleListData, brand, style, factory]);

  useEffect(() => {
    if (srfSearchData && Array?.isArray(srfSearchData)) {
      const brandName = srfSearchData?.map((item) => item?.brand);
      setFormattedBrand([...new Set(brandName)]);
      console.log("formattedBrandjku", formattedBrand);
      const styleList = srfSearchData?.map((item) => item?.style);
      setFormattedStyle([...new Set(styleList)]);
      const factoryList = srfSearchData?.map((item) => item?.factory);
      const uniqueNames = new Set();
      factoryList.forEach((names) => {
        if (Array.isArray(names)) {
          names.forEach((name) => {
            uniqueNames.add(name);
          });
        } else {
          uniqueNames.add(names);
        }
      });
      const uniqueArray = Array.from(uniqueNames);
      setFormattedFactory(uniqueArray);

      let styleSeason = srfSearchData?.map((item) => item?.season);
      let singleSeason = ([...new Set(styleSeason)]);
      setFormattedSeason(singleSeason.join(''))

      const colorList = srfSearchData?.map((item) => item?.color_code);
      setFormattedColor(colorList.join(","));
    }
  }, [srfSearchData]);

  useEffect(() => {
    if (srfTableList && Array?.isArray(srfTableList)) {
      const brandName = srfTableList?.map((item) => item?.brand);
      setFormattedBrand([...new Set(brandName)]);

      const styleList = srfTableList?.map((item) => item?.style);
      setFormattedStyle([...new Set(styleList)]);

      const styleSeason = srfTableList?.map((item) => item?.season);
      let singleSeason = ([...new Set(styleSeason)]);
      setFormattedSeason(singleSeason.join(''))

      const factoryList = srfTableList?.map((item) => item?.factory);
      const uniqueNames = new Set();
      factoryList.forEach((names) => {
        if (Array.isArray(names)) {
          names.forEach((name) => {
            uniqueNames.add(name);
          });
        } else {
          uniqueNames.add(names);
        }
      });
      const uniqueArray = Array.from(uniqueNames);
      setFormattedFactory(uniqueArray);

      const colorList = srfTableList?.map((item) => item?.color_code);
      setFormattedColor(colorList.join(","));
    }
  }, [srfTableList]);

  const onSearchList = () => {
    if (!style && !factory) {
      toast.error("Please select the Style and Factory to search");
    } else if (!style) {
      toast.error("Please select the style");
    } else if (!factory) {
      toast.error("Please select the factory");
    } else {
      dispatch(
        srfTableSearch(brand ? brand : "", style, factory, season ? season : "")
      );
      localStorage.setItem(
        "SrfSearchData",
        JSON.stringify({
          brand: brand ? brand : "",
          style: style,
          factory: factory,
          season: season ? season : "",
        })
      );
      localStorage.setItem(
        "DashboardData",
        JSON.stringify({
          brand: brand,
          style: style,
          factory: factory,
          season: season,
        })
      );
      setBrand();
      setStyle();
      setFactory();
      setSeason();
      dispatch(resetSrfList());
    }
  };
  useEffect(() => {
    if (!srfSearchData) toast.error("Data not found");
  }, [srfSearchData]);

  let exportFormData = new FormData();
  exportFormData.append(
    "style",
    formattedStyle
  );
  exportFormData.append(
    "factory",
    formattedFactory
  );
  
  exportFormData.append(
    "season",
    formattedSeason
  );

  const exportToExcel = () => {
    dispatch(srfExport(exportFormData));
  };

  const onSrfBulkUpload = (e) => {
    let formData = new FormData();
    formData.append("files", e.target.files[0]);
    dispatch(multiSrfSaveData(formData, srfDashboardData));
    e.target.value = null;
    formData = new FormData();
  };
  const onParentStyleClick = (
    formattedStyle,
    formattedFactory,
    formattedBrand,
    formattedSeason
  ) => {
    const style = formattedStyle.toString();
    const factory = formattedFactory.toString();
    const brand = formattedBrand.toString();
    const season = formattedSeason.toString();
    console.log("style,factory,brand",style,factory,brand);
    localStorage.setItem(
      "srtData",
      JSON.stringify({
        brand: brand,
        style: style,
        factory: factory,
        rejected_date: "",
        sale_sample: 0,
        season
      })
    );
    dispatch(resetMassFilter());
    dispatch(resetSeasonFilter());
    dispatch(changingTabsWithCustomButton("true.3"));
    localStorage.setItem("activeKey", "3");
    if (
      brand == "DICKI" ||
      brand == "DICKC" ||
      brand == "DICKW" ||
      brand == "DICKG"
    ) {
      localStorage.setItem("srtActiveKey", "1");
      navigate("/sample-receipt-tracker/dickies");
    } else if (brand == "SAUC") {
      localStorage.setItem("srtActiveKey", "2");
      navigate("/sample-receipt-tracker/saucony");
    } else if (brand == "EDBR") {
      localStorage.setItem("srtActiveKey", "3");
      navigate("/sample-receipt-tracker/eddie-bauer");
    } else if (brand == "PD") {
      localStorage.setItem("srtActiveKey", "4");
      navigate("/sample-receipt-tracker/pd");
    } else {
      localStorage.setItem("srtActiveKey", "1");
      navigate("/sample-receipt-tracker/dickies");
    }
    // navigate("/sample-receipt-tracker");
  };
  return (
    <div>
      <div className="srf-container">
        <div className="srf-header-left">
          <p>{props.title}</p>
        </div>
        <div className="srf-header-right">
          <Button
            disabled={disableButtons}
            icon={<DownloadOutlined />}
            onClick={exportToExcel}
          >
            Export
          </Button>
        </div>
      </div>
      <div className="srf-dropdown-button">
        <div className="srf-dropdown-width srf-drop-down-one">
          <Select
            placeholder={"Brand"}
            allowClear
            value={brand}
            data={singleFilteredBrand}
            onChange={onBrandChange}
          ></Select>
        </div>
        <div className="srf-dropdown-width srf-drop-down-one">
          <DropDown
            allowClear
            onChange={onSeasonChange}
            placeholder={"Season"}
            option={seasons}
            value={season}
          />
        </div>
        <div className="srf-dropdown-width srf-drop-down-one">
          <Select
            showSearch
            placeholder={"Style"}
            allowClear
            value={style}
            data={singleFilteredStyle}
            onChange={onStyleChange}
          ></Select>
        </div>
        <div className="srf-dropdown-width srf-drop-down-one">
          <Select
            placeholder={"Factory"}
            allowClear
            value={factory}
            data={singleFilteredFactory}
            onChange={onFactoryChange}
          ></Select>
        </div>
        <div>
          <Button
            className="dickies-button"
            onClick={onSearchList}
            children={"SEARCH"}
          />
        </div>
      </div>
      <div className="srf-header-bulk-update">
        <Button
          className="dickies-button"
          disabled={disableButtons}
          onClick={() => fileRef.current.click()}
        >
          Bulk Update
        </Button>
        <input
          type="file"
          onChange={onSrfBulkUpload}
          accept="image/jpeg/png/jpg , application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          ref={fileRef}
          hidden
        />
      </div>
      <div className="srf-table-header-one">
        <p className="parent-style">
          Parent Style :{" "}
          <span
            className="style-name"
            onClick={() =>
              onParentStyleClick(
                formattedStyle,
                formattedFactory,
                formattedBrand,
                formattedSeason
              )
            }
          >
            {formattedStyle}
          </span>
        </p>
        <p className="parent-style">Vendor : {formattedFactory}</p>
        <p className="parent-style">Colors : {formattedColor}</p>
        <p className="parent-style">Brand : {formattedBrand}</p>
      </div>
    </div>
  );
};

export default SrfHeader;

// const finalFactoryList = styleListData?.map((item) => item?.factory);
// const uniqueNames = new Set();
// finalFactoryList.forEach((names) => {
//   if (Array.isArray(names)) {
//     names.forEach((name) => {
//       uniqueNames.add(name);
//     });
//   } else {
//     uniqueNames.add(names);
//   }
// });
// const uniqueArray = Array.from(uniqueNames);
// setSingleFilteredFactory(finalFactoryList ? uniqueArray : "");
