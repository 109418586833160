import React, { useState, useEffect } from "react";
import DashboardTable from "./DashboardTable";
import "./Dashboard.css";
import SearchInput from "../../UIComponents/SearchInput/SearchInput";
import Select from "../../UIComponents/DropDown/DropDown";
import {
  dashboardStyleList,
  dashboardStyleListSearch,
  dashboardStyleListSearchFilter,
  expandAccordion,
  reportGeneration,
  resetDashboardData,
  resetDashboardSearch,
} from "../../Redux/actions/Dashboard/Dashboard";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "../../UIComponents/Toast";
import { SyncOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";
import DropDown from "../../UIComponents/DropDown/DropDown";

const Dashboard = () => {
  const dispatch = useDispatch();
  const styleListData = useSelector((state) => state?.Dashboard?.styleList);
  const dashboardDataLoader = useSelector(
    (state) => state?.Dashboard?.listLoader
  );
  const [singleSeason, setSingleSeason] = useState([]);
  const [searchresult, setSearchresult] = useState("");
  const [filterresult, setFilterresult] = useState();
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const onSearchChange = (e) => {
    if (e.target.value.length === 0) {
      setSearchresult(null);
      dispatch(resetDashboardSearch());
      dispatch(dashboardStyleList());
      setFilterresult("");
    } else {
      // localStorage.setItem('dashboardSearch',e.target.value)
      setSearchresult(e.target.value);
    }
  };

  const seasons = [
    {
      value: "F24",
      label: "F24",
    },
    {
      value: "S25",
      label: "S25",
    },
    {
      value: "F25",
      label: "F25",
    },
  ]

  const onSearchEnter = (event) => {
    if (event.key === "Enter") {
      if (searchresult === null) {
        toast.error("Please Enter Details");
        dispatch(resetDashboardSearch());
      } else {
        dispatch(
          dashboardStyleListSearch(
            searchresult,
            filterresult === undefined ? "" : filterresult
          )
        );
        dispatch(expandAccordion(null));
        dispatch(resetDashboardData());
        setSearchresult("");
      }
    }
  };
  const onSeasonChange = (value) => {
    if (value === undefined) {
      dispatch(expandAccordion(null));
      dispatch(resetDashboardSearch());
      dispatch(dashboardStyleList());
      setFilterresult();
    } else {
      setFilterresult(value);
      dispatch(dashboardStyleListSearch(searchresult, value));
    }
  };
  const onRefresh = () => {
    setSearchresult("");
    dispatch(dashboardStyleList());
    dispatch(resetDashboardSearch());
  };
  useEffect(() => {
    if (styleListData && styleListData.length > 0) {
      const seasonArray = styleListData?.map((item) => item?.season);
      setSingleSeason([...new Set(seasonArray)]);
    }
  }, [styleListData]);

  const reportGenerate = () => {
    setIsReportGenerated(true);
  };
  const onContinue = () => {
    setIsReportGenerated(false);
    dispatch(reportGeneration());
  };
  const handleCancel = () => {
    setIsReportGenerated(false);
  };
  return (
    <div>
      <div className="dashboard-wrapper">
        <div className="dashboard-input-dropdown">
          <div className="dashboard-input">
            <SearchInput
              value={searchresult}
              onKeyPress={onSearchEnter}
              onChange={onSearchChange}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ""]/g, "");
              }}
              className="dashboard-search-input"
              placeholder="Search by Parent Style,Brand,Collection,WO,Status"
            />
          </div>
          <div className="dashboard-dropdown-wrapper">
            <DropDown
              allowClear
              onChange={onSeasonChange}
              placeholder={"Season"}
              option={seasons}
              value={filterresult}
            />
            <div className="dashboard-sync-icon">
              <SyncOutlined spin={dashboardDataLoader} onClick={onRefresh} />
            </div>
            <Button onClick={reportGenerate} className="dashboard-button">
              Generate Report
            </Button>
          </div>
        </div>
        <DashboardTable />
      </div>
      <Modal
        title="The report generation might take up to 5 minutes, you want to continue?"
        open={isReportGenerated}
        okText={"save"}
        onCancel={handleCancel}
      >
        <div className="dashboard-modal-buttons">
          <Button className="modal-yes-button" onClick={onContinue}>
            Yes
          </Button>
          <Button className="modal-cancel-btn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Dashboard;
