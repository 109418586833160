import React from 'react'
import HomePage from '../../Components/HomePage/HomePage'

const Homepage = () => {
  return (
    <div>
        <HomePage />
    </div>
  )
}

export default Homepage