import toast, { Toaster } from 'react-hot-toast';

const Toast = () => {
    return (
        <div>
            <Toaster
                position="top-center"
                toastOptions={{
                    style: {
                        padding: '14px 10px',
                        color: '#00000',
                        maxWidth: 600
                    },
                    duration: 5000,
                }}
            />
        </div>
    );
};

export { Toast, toast };