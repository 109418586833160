import * as types from "../../actionType";
import axios from "axios";
import { baseUrl } from "../../../Utiles/Helper";
import Cookies from "js-cookie";
import { toast } from "../../../UIComponents/Toast";
import WarningImg from "../../../images/warning.png";
import { client } from "../../AxiosInterceptor";

export const savecloseToggle = (payload) => ({
  type: types.SAVE_CLOSE_TOGGLE,
  payload,
});
export const resetSeasonFilter = (payload) => ({
  type: types.RESET_SEASON_FILTER,
  payload,
});
export const resetSrtData = (payload) => ({
  type: types.RESET_SRT_DATA,
  payload,
});
export const resetMassFilter = (payload) => ({
  type: types.RESET_MASS_FILTER,
  payload,
});
export const ExFactoryDateVariable = (payload) => ({
  type: types.EXFACTORY_DATE,
  payload,
});
export const reqRecDate = (payload) => ({
  type: types.REQ_REC_DATE,
  payload,
});
export const srtbrandName = (payload) => ({
  type: types.SRT_BRANDS,
  payload,
});
export const srtSeasonName = (payload) => ({
  type: types.SRT_SEASONS,
  payload,
});
export const srtRowIndexForAutoScroll = (payload) => ({
  type: types.ROW_INDEX_FOR_AUTOSCROLL,
  payload,
});
export const srtEXportArray = (payload) => ({
  type: types.SRT_EXPORT_DATA,
  payload,
});
export const selectUnselectSrtTableRows = (payload) => ({
  type: types.SELECT_UNSELECT_SRT_TABLE_ROWS,
  payload,
});
export const clearDateOnSubmit = (payload) => ({
  type: types.CLEAR_DATE_ON_BTN_CLICK,
  payload,
});
const getSrtListLoading = () => ({
  type: types.GET_SRT_LIST_LOADING,
});

const getSrtListSuccess = (data) => ({
  type: types.GET_SRT_LIST_SUCCESS,
  payload: data,
});

const getSrtListFail = (error) => ({
  type: types.GET_SRT_LIST_FAIL,
  payload: error,
});

const massSearchFilterLoading = () => ({
  type: types.MASS_SEARCH_FILTER_LOADING,
});

const massSearchFilterSuccess = (payload) => ({
  type: types.MASS_SEARCH_FILTER_SUCCESS,
  payload,
});

const massSearchFilterFail = (error) => ({
  type: types.MASS_SEARCH_FILTER_FAIL,
  payload: error,
});

const seasonSearchFilterLoading = () => ({
  type: types.SEASON_SEARCH_FILTER_LOADING,
});

const seasonSearchFilterSuccess = (payload) => ({
  type: types.SEASON_SEARCH_FILTER_SUCCESS,
  payload,
});

const seasonSearchFilterFail = (error) => ({
  type: types.SEASON_SEARCH_FILTER_FAIL,
  payload: error,
});

const brandCommentSaverLoading = () => ({
  type: types.BRAND_COMMENT_SAVER_LOADING,
});

const brandCommentSaverSuccess = (payload) => ({
  type: types.BRAND_COMMENT_SAVER_SUCCESS,
  payload,
});

const brandCommentSaverFail = (error) => ({
  type: types.BRAND_COMMENT_SAVER_FAIL,
  payload: error,
});

const designStatusSaverLoading = () => ({
  type: types.DESIGN_STATUS_SAVER_LOADING,
});

const designStatusSaverSuccess = (payload) => ({
  type: types.DESIGN_STATUS_SAVER_SUCCESS,
  payload,
});

const designStatusSaverFail = (error) => ({
  type: types.DESIGN_STATUS_SAVER_FAIL,
  payload: error,
});

const srtDataSaverLoading = () => ({
  type: types.SRT_DATA_SAVE_LOADING,
});

const srtDataSaverSuccess = (payload) => ({
  type: types.SRT_DATA_SAVE_SUCCESS,
  payload,
});

const srtDataSaverFail = (error) => ({
  type: types.SRT_DATA_SAVE_FAIL,
  payload: error,
});

const srtExportLoading = () => ({
  type: types.SRT_EXPORT_LOADING,
});

const srtExportSuccess = (payload) => ({
  type: types.SRT_EXPORT_SUCCESS,
  payload,
});

const srtExportFail = (error) => ({
  type: types.SRT_EXPORT_FAIL,
  payload: error,
});

const srtUnsolicitedExportLoading = () => ({
  type: types.SRT_UNSOLICITED_EXPORT_LOADING,
});

const srtUnsolicitedExportSuccess = (payload) => ({
  type: types.SRT_UNSOLICITED_EXPORT_SUCCESS,
  payload,
});

const srtUnsolicitedExportFail = (error) => ({
  type: types.SRT_UNSOLICITED_EXPORT_FAIL,
  payload: error,
});
const srtReportGenerationLoading = () => ({
  type: types.SRT_REPORT_GENERATION_LOADING,
});

const srtReportGenerationSuccess = (payload) => ({
  type: types.SRT_REPORT_GENERATION_SUCCESS,
  payload,
});

const srtReportGenerationFail = (error) => ({
  type: types.SRT_REPORT_GENERATION_FAIL,
  payload: error,
});

const srtCommentUpdateLoading = () => ({
  type: types.SRT_COMMENT_UPDATE_LOADING,
});
const srtCommentUpdateSucces = (payload) => ({
  type: types.SRT_COMMENT_UPDATE_SUCCESS,
  payload,
});
const srtCommentUpdateFail = (error) => ({
  type: types.SRT_COMMENT_UPDATE_FAIL,
  payload: error,
});
const srtSearchFilterLoading = () => ({
  type: types.SRT_SEARCH_FILTER_LOADING,
});

const srtSearchFilterSuccess = (payload) => ({
  type: types.SRT_SEARCH_FILTER_SUCCESS,
  payload,
});

const srtSearchFilterFail = (error) => ({
  type: types.SRT_SEARCH_FILTER_FAIL,
  payload: error,
});

const srtSeasonSearchFilterLoading = () => ({
  type: types.SRT_SEASON_SEARCH_FILTER_LOADING,
});

const srtSeasonSearchFilterSuccess = (payload) => ({
  type: types.SRT_SEASON_SEARCH_FILTER_SUCCESS,
  payload,
});

const srtSeasonSearchFilterFail = (error) => ({
  type: types.SRT_SEASON_SEARCH_FILTER_FAIL,
  payload: error,
});

const srtBulkSaveLoading = () => ({
  type: types.SRT_BULK_SAVE_LOADING,
});

const srtBulkSaveSuccess = (payload) => ({
  type: types.SRT_BULK_SAVE_SUCCESS,
  payload,
});

const srtBulkSaveFail = (error) => ({
  type: types.SRT_BULK_SAVE_FAIL,
  payload: error,
});

const getSrtUnsolicitedListLoading = () => ({
  type: types.GET_SRT_UNSOLICITED_LIST_LOADING,
});

const getSrtUnsolicitedListSuccess = (data) => ({
  type: types.GET_SRT_UNSOLICITED_LIST_SUCCESS,
  payload: data,
});

const getSrtUnsolicitedListFail = (error) => ({
  type: types.GET_SRT_UNSOLICITED_LIST_FAIL,
  payload: error,
});


const srtUnsolicitedBulkUploadLoading = () => ({
  type: types.SRT_UNSOLICITED_BULK_UPLOAD_LOADING,
});

const srtUnsolicitedBulkUploadSuccess = (payload) => ({
  type: types.SRT_UNSOLICITED_BULK_UPLOAD_SUCCESS,
  payload,
});

const srtUnsolicitedBulkUploadFail = (error) => ({
  type: types.SRT_UNSOLICITED_BULK_UPLOAD_FAIL,
  payload: error,
});


let url = process.env.REACT_APP_BASEURL;

export const getSrtInitialList = (brand, style, factory, rejected_date, sale_sample, season) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtListConfig = {
      method: "GET",
      url: `srt/list?brand=${brand}&style=${style}&factory=${factory}&rejected_date=${rejected_date}&sale_sample=${sale_sample}&season=${season}`,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(getSrtListLoading());
    client(srtListConfig)
      .then((resp) => {
        dispatch(getSrtListSuccess(resp.data));
        dispatch(resetSeasonFilter([]));
        dispatch(resetMassFilter([]));
      })
      .catch((error) => {
        console.log(error, "error in srt");
        dispatch(getSrtListFail(error));
      });
  };
};

export const massSearchFilter = (
  searchfilter,
  brandfilter,
  colorfilter,
  sizefilter,
  statusfilter,
  vendorfilter,
  sale_samplefilter,
  seasonfilter
) => {
  console.log(
    "dataformass",
    searchfilter,
    colorfilter,
    sizefilter,
    statusfilter,
    vendorfilter,
    seasonfilter
  );
  const secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtMassFilterConfig = {
      method: "POST",
      url: `style/mass_filter/?searchfilter=${searchfilter}&brandfilter=${brandfilter}&colorfilter=${colorfilter}&sizefilter=${sizefilter}&statusfilter=${statusfilter}&vendorfilter=${vendorfilter}&sale_samplefilter=${sale_samplefilter}&seasonfilter=${seasonfilter}`,
      searchfilter,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(massSearchFilterLoading());
    client(srtMassFilterConfig)
      .then((resp) => {
        dispatch(massSearchFilterSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(massSearchFilterFail(error));
        toast.error("Data not found");
      });
  };
};

export const seasonSearchFilter = (brand, search, factory, sale_sample) => {
  const secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtSeasonFilterConfig = {
      method: "POST",
      url: `srt/search?brand=${brand}&search=${search}&factory=${factory}&sale_sample=${sale_sample}`,
      brand,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(seasonSearchFilterLoading());
    client(srtSeasonFilterConfig)
      .then((resp) => {
        dispatch(seasonSearchFilterSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(seasonSearchFilterFail(error));
        toast.error("Data Not Found");
      });
  };
};

export const brandCommentSaver = (data, dataOfRow) => {
  const srtMassFilterData = JSON.parse(localStorage.getItem("srtMassData"));
  const srtData = JSON.parse(localStorage.getItem("srtData"));
  const secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtCmtConfig = {
      method: "POST",
      url: `srt/cmts`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(brandCommentSaverLoading());
    client(srtCmtConfig)
      .then((resp) => {
        dispatch(brandCommentSaverSuccess(resp.data));
        toast.success("Comment Saved");
        srtMassFilterData
          ? dispatch(
              massSearchFilter(
                srtMassFilterData?.searchFilter
                  ? srtMassFilterData?.searchFilter
                  : "",
                srtMassFilterData?.brandfilter
                  ? srtMassFilterData?.brandfilter
                  : "",
                srtMassFilterData?.colorFilter
                  ? srtMassFilterData?.colorFilter
                  : "",
                srtMassFilterData?.sizeFilter
                  ? srtMassFilterData?.sizeFilter
                  : "",
                srtMassFilterData?.statusFilter
                  ? srtMassFilterData?.statusFilter
                  : "",
                srtMassFilterData?.vendorFilter
                  ? srtMassFilterData?.vendorFilter
                  : "",
                srtMassFilterData?.sale_samplefilter 
                  ? srtMassFilterData?.sale_samplefilter
                  : 0,
                srtMassFilterData?.seasonFilter
                  ? srtMassFilterData?.seasonFilter
                  : "",
              )
            )
          : dispatch(
              getSrtInitialList(
                srtData?.brand,
                srtData?.style,
                srtData?.factory,
                "",
                srtData?.sale_sample,
                srtData?.season
              )
            );
        dispatch(resetMassFilter([]));
        dispatch(resetSeasonFilter([]));
      })
      .catch((error) => {
        dispatch(brandCommentSaverFail(error));
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Could'nt save the Comment"
        );
      });
  };
};

export const designStatusSaver = (
  style,
  brand,
  factory,
  size,
  season,
  color_code,
  request_no,
  brand_status,
  design_status,
  pd_status,
  qa_status
) => {
  const srtMassFilterData = JSON.parse(localStorage.getItem("srtMassData"));
  const srtData = JSON.parse(localStorage.getItem("srtData"));
  const secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtStatusConfig = {
      method: "POST",
      url: `style/srt_status_save/?style=${style}&brand=${brand}&factory=${factory}&size=${size}&season=${season}&color_code=${color_code}&request_no=${request_no}&brand_status=${brand_status}&design_status=${design_status}&pd_status=${pd_status}&qa_status=${qa_status}`,
      brand,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(designStatusSaverLoading());
    client(srtStatusConfig)
      .then((resp) => {
        dispatch(designStatusSaverSuccess(resp.data));
        const splitArray = resp.data.status.split("_");
        const finalArray = splitArray.join(" ");
        
        toast.success(finalArray, {
          style: {
            maxWidth: 700,
          },
        });
        srtMassFilterData
          ? dispatch(
              massSearchFilter(
                srtMassFilterData?.searchFilter
                  ? srtMassFilterData?.searchFilter
                  : "",
                srtMassFilterData?.brandfilter
                  ? srtMassFilterData?.brandfilter
                  : "",
                srtMassFilterData?.colorFilter
                  ? srtMassFilterData?.colorFilter
                  : "",
                srtMassFilterData?.sizeFilter
                  ? srtMassFilterData?.sizeFilter
                  : "",
                srtMassFilterData?.statusFilter
                  ? srtMassFilterData?.statusFilter
                  : "",
                srtMassFilterData?.vendorFilter
                  ? srtMassFilterData?.vendorFilter
                  : "",
                srtMassFilterData?.sale_samplefilter 
                  ? srtMassFilterData?.sale_samplefilter
                  : 0,
                srtMassFilterData?.seasonFilter
                  ? srtMassFilterData?.seasonFilter
                  : "",
              )
            )
          : dispatch(
              getSrtInitialList(
                srtData?.brand,
                srtData?.style,
                srtData?.factory,
                "",
                srtData?.sale_sample,
                season
              )
            );
      })
      .catch((error) => {
        dispatch(designStatusSaverFail(error));
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Could'nt save the Status"
        );
      });
  };
};

export const srtDataSaver = (
  style,
  brand,
  factory,
  size,
  season,
  color_code,
  request_no,
  tracking_link,
  promised_date,
  received_date,
  qty_received,
) => {
  const srtMassFilterData = JSON.parse(localStorage.getItem("srtMassData"));
  const srtData = JSON.parse(localStorage.getItem("srtData"));
  const secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtDataSaverConfig = {
      method: "POST",
      url: `style/srt_save/?style=${style}&brand=${brand}&factory=${factory}&size=${size}&season=${season}&color_code=${color_code}&request_no=${request_no}&tracking_link=${tracking_link}&promised_date=${promised_date}&received_date=${received_date}&qty_received=${qty_received}`,
      brand,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(srtDataSaverLoading());
    client(srtDataSaverConfig)
      .then((resp) => {
        const splitArray = resp.data.status.split("_");
        const finalArray = splitArray.join(" ");
        dispatch(srtDataSaverSuccess(resp.data));
        srtMassFilterData
          ? dispatch(
              massSearchFilter(
                srtMassFilterData?.searchFilter
                  ? srtMassFilterData?.searchFilter
                  : "",
                srtMassFilterData?.brandfilter
                  ? srtMassFilterData?.brandfilter
                  : "",
                srtMassFilterData?.colorfilter
                  ? srtMassFilterData?.colorfilter
                  : "",
                srtMassFilterData?.sizeFilter
                  ? srtMassFilterData?.sizeFilter
                  : "",
                srtMassFilterData?.statusFilter
                  ? srtMassFilterData?.statusFilter
                  : "",
                srtMassFilterData?.vendorFilter
                  ? srtMassFilterData?.vendorFilter
                  : "",
                srtMassFilterData?.sale_samplefilter 
                  ? srtMassFilterData?.sale_samplefilter
                  : 0,
                srtMassFilterData?.seasonFilter
                  ? srtMassFilterData?.seasonFilter
                  : "",
              )
            )
          : dispatch(
              getSrtInitialList(
                srtData?.brand,
                srtData?.style,
                srtData?.factory,
                "",
                srtData?.sale_sample,
                srtData?.season
              )
            );
        dispatch(resetMassFilter([]));
        dispatch(resetSeasonFilter([]));
        toast.success(finalArray, {
          style: {
            maxWidth: 720,
          },
        });
      })
      .catch((error) => {
        dispatch(srtDataSaverFail(error));
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Could'nt save the Data"
        );
      });
  };
};

export const srtExport = (data) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtExportConfig = {
      method: "POST",
      url: `srt/export`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
      responseType: "arraybuffer",
    };
    dispatch(srtExportLoading());
    client(srtExportConfig)
      .then((resp) => {
        dispatch(srtExportSuccess(resp.data));
        var blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var fileName = "SRT Export.xlsx";
        saveAs(blob, fileName);
      })
      .catch((error) => {
        dispatch(srtExportFail(error));
        toast.error("Please select the style to export");
      });
  };
};
export const srtReportGeneration = (factory, fromDate, toDate, season) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtReportGenConfig = {
      method: "POST",
      url: `srt/report?factory=${factory}&from_date=${fromDate}&to_date=${toDate}&season=${season}`,
      factory,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
      responseType: "arraybuffer",
    };
    dispatch(srtReportGenerationLoading());
    client(srtReportGenConfig)
      .then((resp) => {
        dispatch(srtReportGenerationSuccess(resp.data));
        var blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var fileName = "Srt Report.xlsx";
        saveAs(blob, fileName);
      })
      .catch((error) => {
        dispatch(srtReportGenerationFail(error));
        toast.error("Data Not Found");
      });
  };
};
export const CommentUpdate = (data, dataOfRow) => {
  const srtMassFilterData = JSON.parse(localStorage.getItem("srtMassData"));
  const srtData = JSON.parse(localStorage.getItem("srtData"));
  const secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtCmtUpdateConfig = {
      method: "POST",
      url: `srt/cmts/update`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(srtCommentUpdateLoading());
    client(srtCmtUpdateConfig)
      .then((resp) => {
        dispatch(srtCommentUpdateSucces(resp.data));
        toast.success("Comment Updated");
        srtMassFilterData
          ? dispatch(
              massSearchFilter(
                srtMassFilterData?.searchFilter
                  ? srtMassFilterData?.searchFilter
                  : "",
                srtMassFilterData?.brandfilter
                  ? srtMassFilterData?.brandfilter
                  : "",
                srtMassFilterData?.colorfilter
                  ? srtMassFilterData?.colorfilter
                  : "",
                srtMassFilterData?.sizeFilter
                  ? srtMassFilterData?.sizeFilter
                  : "",
                srtMassFilterData?.statusFilter
                  ? srtMassFilterData?.statusFilter
                  : "",
                srtMassFilterData?.vendorFilter
                  ? srtMassFilterData?.vendorFilter
                  : "",
                srtMassFilterData?.sale_samplefilter 
                  ? srtMassFilterData?.sale_samplefilter
                  : 0,
                srtMassFilterData?.seasonFilter
                  ? srtMassFilterData?.seasonFilter
                  : "",
              )
            )
          : dispatch(
              getSrtInitialList(
                srtData?.brand,
                srtData?.style,
                srtData?.factory,
                "",
                srtData?.sale_sample,
                srtData?.season
              )
            );
        dispatch(resetMassFilter([]));
        dispatch(resetSeasonFilter([]));
      })
      .catch((error) => {
        dispatch(srtCommentUpdateFail(error));
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Could'nt update the Comment"
        );
      });
  };
};
export const srtSearchFilter = (brand,navigate) => {
  window.open("/sample-receipt-tracker", '_blank');
  localStorage.setItem("activeKey", "3");
  if (
    brand == "DICKI" ||
    brand == "DICKC" ||
    brand == "DICKW" ||
    brand == "DICKG"
  ) {
    localStorage.setItem("srtActiveKey", "1");
    navigate("/sample-receipt-tracker/dickies");
  } else if (brand == "SAUC") {
    localStorage.setItem("srtActiveKey", "2");
    navigate("/sample-receipt-tracker/saucony");
  } else if (brand == "EDBR") {
    localStorage.setItem("srtActiveKey", "3");
    navigate("/sample-receipt-tracker/eddie-bauer");
  } else if (brand == "PD") {
    localStorage.setItem("srtActiveKey", "4");
    navigate("/sample-receipt-tracker/pd");
  } else {
    localStorage.setItem("srtActiveKey", "1");
    navigate("/sample-receipt-tracker/dickies");
  }
  // const secure_token = Cookies.get("access_token");
  // return function (dispatch) {
  //   const srtSearchFilterConfig = {
  //     method: "POST",
  //     url: `srt/search?brand=${brand}&search=${search}&factory=${factory}`,
  //     brand,
  //     headers: {
  //       Authorization: `Bearer ${secure_token}`,
  //     },
  //   };
  //   dispatch(srtSearchFilterLoading());
  //   client(srtSearchFilterConfig)
  //     .then((resp) => {
  //       dispatch(srtSearchFilterSuccess(resp.data));
  //     })
  //     .catch((error) => {
  //       dispatch(srtSearchFilterFail(error));
  //     });
  // };
};
export const srtSeasonSearchFilter = (brand, season, sale_sample) => {
  const secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtSeasonFilterConfig = {
      method: "GET",
      url: `srt/season_search?brand=${brand}&season=${season}&sale_sample=${sale_sample}`,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(srtSeasonSearchFilterLoading());
    client(srtSeasonFilterConfig)
      .then((resp) => {
        dispatch(srtSeasonSearchFilterSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(srtSeasonSearchFilterFail(error));
        dispatch(getSrtInitialList(brand, "", "", "",sale_sample,season));
      });
  };
};

export const srtBulkSave = (data, brandNameFromApi) => {
  const srtMassFilterData = JSON.parse(localStorage.getItem("srtMassData"));
  const srtData = JSON.parse(localStorage.getItem("srtData"));
  const secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtBulkSaveConfig = {
      method: "POST",
      url: `style/srt_bulk_save/`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(srtBulkSaveLoading());
    client(srtBulkSaveConfig)
      .then((resp) => {
        dispatch(srtBulkSaveSuccess(resp.data));
        srtMassFilterData
          ? dispatch(
              massSearchFilter(
                srtMassFilterData?.searchFilter
                  ? srtMassFilterData?.searchFilter
                  : "",
                srtMassFilterData?.brandfilter
                  ? srtMassFilterData?.brandfilter
                  : "",
                srtMassFilterData?.colorfilter
                  ? srtMassFilterData?.colorfilter
                  : "",
                srtMassFilterData?.sizeFilter
                  ? srtMassFilterData?.sizeFilter
                  : "",
                srtMassFilterData?.statusFilter
                  ? srtMassFilterData?.statusFilter
                  : "",
                srtMassFilterData?.vendorFilter
                  ? srtMassFilterData?.vendorFilter
                  : "",
                srtMassFilterData?.sale_samplefilter 
                  ? srtMassFilterData?.sale_samplefilter
                  : 0,
                srtMassFilterData?.seasonFilter
                  ? srtMassFilterData?.seasonFilter
                  : "",
              )
            )
          : dispatch(
              getSrtInitialList(
                srtData?.brand,
                srtData?.style,
                srtData?.factory,
                "",
                srtData?.sale_sample,
                srtData?.season
              )
            );
        dispatch(resetMassFilter([]));
        dispatch(resetSeasonFilter([]));
      })
      .catch((error) => {
        console.log("bulkerror", error?.response?.data?.error);
        dispatch(srtBulkSaveFail(error));
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Could'nt Update Data"
        );
      });
  };
};

export const getSrtUnsolicitedSamplesList = () => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtUnsolicitedListConfig = {
      method: "GET",
      url: `unsolicited/samples/list`,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(getSrtUnsolicitedListLoading());
    client(srtUnsolicitedListConfig)
      .then((resp) => {
        dispatch(getSrtUnsolicitedListSuccess(resp.data));
        dispatch(resetSeasonFilter([]));
        dispatch(resetMassFilter([]));
      })
      .catch((error) => {
        console.log(error, "error in srt");
        dispatch(getSrtUnsolicitedListFail(error));
      });
  };
};

export const srtUnsolicitedBulkUploadSave = (data, brandNameFromApi) => {
  const srtMassFilterData = JSON.parse(localStorage.getItem("srtMassData"));
  const srtData = JSON.parse(localStorage.getItem("srtData"));
  const secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtUnsolicitedBulkUploadConfig = {
      method: "POST",
      url: `unsolicited/samples/bulk/save`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(srtUnsolicitedBulkUploadLoading());
    client(srtUnsolicitedBulkUploadConfig)
      .then((resp) => {
        dispatch(srtUnsolicitedBulkUploadSuccess(resp.data));
        srtMassFilterData
          ? dispatch(
              massSearchFilter(
                srtMassFilterData?.searchFilter
                  ? srtMassFilterData?.searchFilter
                  : "",
                srtMassFilterData?.brandfilter
                  ? srtMassFilterData?.brandfilter
                  : "",
                srtMassFilterData?.colorfilter
                  ? srtMassFilterData?.colorfilter
                  : "",
                srtMassFilterData?.sizeFilter
                  ? srtMassFilterData?.sizeFilter
                  : "",
                srtMassFilterData?.statusFilter
                  ? srtMassFilterData?.statusFilter
                  : "",
                srtMassFilterData?.vendorFilter
                  ? srtMassFilterData?.vendorFilter
                  : "",
                srtMassFilterData?.sale_samplefilter 
                  ? srtMassFilterData?.sale_samplefilter
                  : 0,
                srtMassFilterData?.seasonFilter
                  ? srtMassFilterData?.seasonFilter
                  : "",
              )
            )
          : dispatch(
            getSrtUnsolicitedSamplesList(
                srtData?.brand,
                srtData?.style,
                srtData?.factory,
                "",
                srtData?.sale_sample,
                srtData?.season
              )
            );
        dispatch(resetMassFilter([]));
        dispatch(resetSeasonFilter([]));
      })
      .catch((error) => {
        console.log("bulkerror", error?.response?.data?.error);
        dispatch(srtUnsolicitedBulkUploadFail(error));
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Could'nt Update Data"
        );
      });
  };
};


export const srtUnsolicitedBulkExport = (data) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const srtUnsolicitedExportConfig = {
      method: "POST",
      url: `unsolicited/samples/export`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
      responseType: "arraybuffer",
    };
    dispatch(srtUnsolicitedExportLoading());
    client(srtUnsolicitedExportConfig)
      .then((resp) => {
        dispatch(srtUnsolicitedExportSuccess(resp.data));
        var blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var fileName = "SRT Export.xlsx";
        saveAs(blob, fileName);
      })
      .catch((error) => {
        dispatch(srtUnsolicitedExportFail(error));
        toast.error("Please select the style to export");
      });
  };
};