import React, { useState, useEffect } from "react";
import { Table } from "antd";
import "./DashboardTable.css";
import AccordionContent from "./AccordionContent";
import { useSelector, useDispatch } from "react-redux";
import {
  dashboardRowData,
  dashboardStyleDetailsList,
  expandAccordion,
  resetDashboardStyleData,
} from "../../Redux/actions/Dashboard/Dashboard";
import BeatLoader from "react-spinners/BeatLoader";

const columns = [
  {
    title: "Season",
    dataIndex: "Season",
    key: "Season",
    // width: 5,
  },
  {
    title: "Parent Style",
    dataIndex: "parentStyle",
    key: "parentStyle",
    // width: 19,
  },
  {
    title: "Brand",
    dataIndex: "brand",
    key: "brand",
    // width: 15,
  },
  {
    title: "Collection",
    dataIndex: "collection",
    key: "collection",
    // width: 19,
  },
  {
    title: "Style Description",
    dataIndex: "styleDescription",
    key: "styleDescription",
    // width: 20,
  },
  {
    title: "WO",
    dataIndex: "wo",
    key: "wo",
    // width: 10,
  },
  {
    title: "Status",
    dataIndex: "status",
    // width: 15,
    key: "x",
  },
  Table.EXPAND_COLUMN,
];

const DashboardTable = () => {
  const dispatch = useDispatch();
  const expandedRowKey = useSelector((state) => state?.Dashboard?.expandRowkey);
  console.log("expandedRowKey", expandedRowKey);
  const styleListData = useSelector((state) => state?.Dashboard?.styleList);
  const dashboardSearch = useSelector(
    (state) => state?.Dashboard?.styleListSearch
  );
  console.log("styleListDatadd", styleListData, dashboardSearch);
  const dashboardSearchLoader = useSelector(
    (state) => state?.Dashboard?.searchLoader
  );
  const styleListDetailedData = useSelector(
    (state) => state?.Dashboard?.styleDetailsData
  );
  console.log("styleListDetailedData", styleListDetailedData);
  const SeasonSearchData = useSelector(
    (state) => state?.Dashboard?.styleListSearchFilter
  );
  const [accordionKey, setAccordianKey] = useState();
  const styleDetailsDashboard = useSelector(
    (state) => state?.Dashboard?.styleDetailsData
  );

  const initialData = () => {
    if (SeasonSearchData && SeasonSearchData?.length > 0) {
      return SeasonSearchData;
    } else if (dashboardSearch && dashboardSearch?.length > 0) {
      return dashboardSearch;
    } else if (styleListData && styleListData?.length > 0) {
      return styleListData;
    } else return [];
  };

  const truncateStyleDescription = (description) => {
    if (description && description.length > 25) {
      return description.substring(0, 25) + "...";
    }
    return description;
  };

  const data = [];
  {
    initialData()?.map((item, index) => {
      data.push({
        key: index.toString(),
        id: index,
        Season: item?.season,
        parentStyle: item?.style,
        brand: item?.brand,
        collection: item?.collection,
        styleDescription: truncateStyleDescription(item?.description),
        wo: item?.wo,
        season_id: item?.season_id,
        season: item?.season,
        status: (
          <p
            className={
              item.status === "In Development"
                ? "development-btn"
                : item.status === "Product Ready"
                ? "launch-btn"
                : item.status === "Dropped"
                ? "dropped-btn"
                : item.status === "Execute"
                ? "adopted-btn"
                : item.status === "TP Verified"
                ? "verified-btn"
                : ""
            }
          >
            {item.status}
          </p>
        ),
      });
    });
  }

  const handleRowClick = (record) => {
    console.log("here is the record", record);
    dispatch(
      dashboardRowData({
        status: record?.status?.props?.children,
        style: record?.parentStyle,
        season_id: record?.season_id,
        season: record?.season,
        brand: record?.brand,
      })
    );
  };

  useEffect(() => {
    if (
      styleListDetailedData !== {} &&
      styleListDetailedData?.vendors?.length > 0
    ) {
      dispatch(expandAccordion(accordionKey));
    }
  }, [styleListDetailedData]);

  return (
    <div className="dashboard-table-wrapper">
      {dashboardSearchLoader ? (
        <BeatLoader
          color={"#0C3D5E"}
          loading={dashboardSearchLoader}
          cssOverride={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            borderColor: "#0C3D5E",
          }}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <Table
          expandRowByClick
          rowClassName={() => "rowClassName1"}
          columns={columns}
          dataSource={data}
          expandedRowKeys={expandedRowKey ? [expandedRowKey] : []}
          onExpand={(expanded, record) => {
            console.log("reocorddetails", expanded, record);
            if (expanded) {
              setAccordianKey(record.key);
              const style = record?.parentStyle;
              const season_id = record?.season_id;
              const season = record?.season;
              const brand = record?.brand;
              dispatch(
                dashboardStyleDetailsList(style, season_id, season, brand)
              );
            } else {
              dispatch(resetDashboardStyleData({}));
              dispatch(expandAccordion(null));
            }
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                handleRowClick(record, rowIndex);
              },
            };
          }}
          expandable={{
            expandedRowRender: (record) => <AccordionContent />,
            rowExpandable: (record) => record.name !== "Not Expandable",
          }}
        />
      )}
    </div>
  );
};

export default DashboardTable;
