import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import EmptyChat from '../../../images/EmptyChat.png';
const Image = ({className,onClick,src,alt,commentRecieved,user}) => {
  const [commentData,setCommentData] = useState()
  const [mappedData, setMappedData] = useState([])
  console.log("commentRecieved",commentRecieved,commentData);
  useEffect(() => {
    if (commentRecieved && commentRecieved.length > 0) {
      const mapData = commentRecieved.reduce((acc, item) => {
        const existingUser = acc.find(user => user.username === item.username);
    
        if (existingUser) {
          existingUser.comments.push(item.comments);
          existingUser.last_comment = item.comments;
          existingUser.last_id = item.id;
          existingUser.updated_at = item.updated_at;
          existingUser.comment_count = item.comment_count;
        } else {
          acc.push({
            username: item.username,
            comments: [item.comments],
            last_comment: item.comments,
            last_id: item.id,
            updated_at: item.updated_at,
            comment_count: item.comment_count
          });
        }
    
        return acc;
      }, []);
      setMappedData(mapData);
    }
  }, [commentRecieved]);
  
  console.log("mappedData",mappedData);
  const comment = commentRecieved && Array?.isArray(commentRecieved) ? 
    <>
    <p className="tooltip-comment"><b>Comment</b></p>
    {mappedData?.map((item,index) => {
      return (
      <div key={item.last_id}>
       <div className="tooltip-name-date-container">
       <p className="tooltip-comment"><b>{item?.username}</b></p>
       <p className="tooltip-comment-date-time">{item?.updated_at}</p>
       </div>
       {item.comments.map((comment, index) => (
          <div key={index}>
            {comment.split(/(?<=\n)(?=[a-zA-Z])/).map((line, lineIndex) => (
              <p key={lineIndex}className="tooltip-comment">
                  {lineIndex === 0 ? `${index + 1}. ${line}` : line}
              </p>
            ))}
          </div>
        ))}
      </div>
      )
    })}
     </>: <></>

useEffect(() => {
  if (commentRecieved && Array?.isArray(commentRecieved)) {
    commentRecieved?.map((item) => {
      setCommentData(item)
    })
  }
},[commentRecieved])

  return (
    <div>
        <div className="srt-table-data-three">
      <div className="srt-date-pickers">
      {commentData?.comments == " " || commentData?.comments == "" || commentData?.comments == null || commentData?.comments == undefined  ?  
        <img className={className}  src={EmptyChat} alt={alt} /> :
        <Tooltip overlayInnerStyle={{width: "fit-content",
        height: "fit-content",padding: '20px',color:'black'}} color={'white'} placement="right" title={comment} >
        <img className={className} onClick={onClick} src={src} alt={alt} />
        </Tooltip>
      }
        </div></div>
        
    </div>
  )
}

export default Image