import React from "react";
import { Select } from "antd";
import { DownOutlined } from "@ant-design/icons";

const { option } = Select;
const DropDown = (props) => {
  // const {value}=props
  let options = props?.data?.map((data, index) => {
    if (data && Object.keys(data).includes("disabled")) {
      return (
        <option disabled={data?.disabled} key={index} value={data?.name}>
          {data?.name}
        </option>
      );
    } else {
      return (
        <option key={index} value={data?.id || data || data?.name || value}>
          {data?.name || data || value}
        </option>
      );
    }
  });

  return (
    <>
      <Select
        // prefixIcon={<DownOutlined />}
        mode={props.mode}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        optionFilterProp="children"
        value={props.value}
        showSearch={props.showSearch}
        onChange={props.onChange}
        onSearch={props.onSearch}
        listHeight={props.listHeight}
        disabled={props.disabled}
        className={props.className}
        options={props.option}
        allowClear={props.allowClear}
        onClick={props.onClick}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options}
      </Select>
    </>
  );
};

export default DropDown;
