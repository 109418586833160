import * as types from "../../actionType";
import axios from "axios";
import { baseUrl } from "../../../Utiles/Helper";
import Cookies from "js-cookie";
import { toast } from "../../../UIComponents/Toast";
import { callMsGraph } from "../../../Components/Login/Graph";
import { client } from "../../AxiosInterceptor";

const profileDetailsLoading = () => ({
  type: types.PROFILE_DETAIL_LOADING,
});
const profileDetailsSuccess = (data) => ({
  type: types.PROFILE_DETAIL_SUCCESS,
  payload: data,
});
const profileDetailsFail = (error) => ({
  type: types.PROFILE_DETAIL_FAIL,
  payload: error,
});

const getGraphDataLoading = () => ({
  type: types.GET_GRAPH_DATA_LOADING,
});
const getGraphDataSuccess = (data) => ({
  type: types.GET_GRAPH_DATA_SUCCESS,
  payload: data,
});
const getGraphDataFail = (error) => ({
  type: types.GET_GRAPH_DATA_FAIL,
  payload: error,
});

let url = process.env.REACT_APP_BASEURL;

export const profileDetail = (email) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "GET",
      url: `view_user?email=${email}`,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(profileDetailsLoading());
    client(Config)
      .then((resp) => {
        dispatch(profileDetailsSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(profileDetailsFail(error));
      });
  };
};

export const getGraphData = () => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    dispatch(getGraphDataLoading());
    callMsGraph(secure_token)
      .then((resp) => {
        dispatch(getGraphDataSuccess(resp));
      })
      .catch((error) => {
        dispatch(getGraphDataFail(error));
      });
  };
};
