import React from 'react'
import { Input } from 'antd';
import {SearchOutlined} from '@ant-design/icons'
import '../../Components/Dashboard/Dashboard.css'
const SearchInput = (props) => {
  return (
    <div>
        <Input 
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        id={props.id}
        suffix={props.suffix}
        type={props.type}
        disabled={props.disabled}
        onInput={props.onInput}
        onKeyPress={props.onKeyPress}
        onKeyDown={props.onKeyDown}
        onmousedown={props.onmousedown}
        maxLength={props.maxLength} 
        className={props.className} 
        prefix={<SearchOutlined className='search-icon' />} 
        placeholder={props.placeholder} />
    </div>
  )
}

export default SearchInput