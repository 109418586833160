import React from 'react'

const TableDatePicker = ({presentDate}) => {

  return (
    <div className="srt-table-data-three">
      <div className="srt-date-pickers">
        <p>{presentDate}</p>
        </div>
    </div>
  )
}

export default TableDatePicker