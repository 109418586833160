import React, { useEffect, useState } from "react";
import "./ParentStyle.css";
import { changingTabsWithCustomButton } from "../../../../Redux/actions/Dashboard/Dashboard";
import { resetSrfSearchData } from "../../../../Redux/actions/Srf/Srf";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const ParentStyle = ({
  sockId,
  sockName,
  vendor,
  color,
  size,
  promsiedExFactory,
  brand,
  season,
}) => {
  const [reqExFactoryDate, setReqExFactoryDate] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      promsiedExFactory?.request_details &&
      Array.isArray(promsiedExFactory?.request_details)
    ) {
      promsiedExFactory?.request_details?.map((data, index) => {
        setReqExFactoryDate(data?.req_exfactory);
      });
    }
  }, [promsiedExFactory]);

  const onParentStyleClicked = () => {
    localStorage.setItem(
      "DashboardData",
      JSON.stringify({
        style: sockId,
        factory: vendor,
        season: season,
        brand: brand,
      })
    );
    dispatch(resetSrfSearchData([]));
    localStorage.setItem("RequestSend", false);
    dispatch(changingTabsWithCustomButton("true.2"));
    localStorage.removeItem("SrfSearchData");
    navigate("/sample-request-form");
  };
  return (
    <div className="srt-table-data-one">
      <p className="socks-id" onClick={onParentStyleClicked}>
        {sockId}
      </p>
      <p className="common-font">{sockName}</p>
      <div className="vendor-color-size">
        <p className="common-font">
          Vendor : <span className="vendor-name">{vendor}</span>
        </p>
        <p className="common-font">
          Color : <span className="socks-color">{color}</span>
        </p>
        <p className="common-font">
          Size : <span className="socks-size">{size}</span>
        </p>
      </div>
      <p className="common-font">
        Promised Ex-Factory :{" "}
        <span className="promised-ex-factory">{reqExFactoryDate}</span>
      </p>
    </div>
  );
};

export default ParentStyle;
