import React from 'react'

const RowSavedData = ({resData}) => {
  return (
    <div className="srt-table-data-three">
    <div className="srt-date-pickers">
     <p>{resData}</p>
    </div>
    </div>
  )
}

export default RowSavedData