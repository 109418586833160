import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Homepage from "../../Pages/HomePage/Homepage";
import Login from "../../Pages/Login/Login";
import Srt from "../../Pages/SRT/Srt";
import Dashboard from "../Dashboard/Dashboard";
import Header from "../Header/Header";
import SrfTable from "../SRF/Table";
import PrivateRoute from "./PrivateRoute";
import { useEffect } from "react";
import { srfTableListData } from "../../Redux/actions/Srf/Srf";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import StatusUpdate from "../StatusUpdate/StatusUpdate";

const ComponentsRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let secure_token = Cookies.get("access_token");
  const srfPathName = localStorage.getItem("srfPath");
  const path = srfPathName ? srfPathName : location.pathname;

  useEffect(() => {
    const prefix = "/sample-request-form/";
    if (path.includes(prefix)) {
      const params = path.slice(prefix.length).split("/");

      const hasParams = params.length > 0;

      if (secure_token && hasParams) {
        const paramsObject = {};

        for (let i = 0; i < params.length; i++) {
          paramsObject[`param${i + 1}`] = params[i];
        }
        localStorage.setItem(
          "DashboardData",
          JSON.stringify({
            style: paramsObject?.param2,
            factory: paramsObject?.param3,
            season: paramsObject?.param4,
            brand: paramsObject?.param1,
          })
        );
        // dispatch(srfTableListData(paramsObject?.param2 ,paramsObject?.param3 ,paramsObject?.param4))
        localStorage.setItem("activeKey", "2");
        localStorage.removeItem("srfPath");
        localStorage.removeItem("currentPage");
      }
    }
  }, [path]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route exact path="/" element={<PrivateRoute />}>
          <Route exact path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <>
                <Homepage />
                <div>
                  <Dashboard />
                </div>
              </>
            }
          />
          <Route
            path="/sample-request-form"
            element={
              <>
                <Homepage />
                <div>
                  <SrfTable />
                </div>
              </>
            }
          />
          <Route
            path="/sample-request-form/*"
            element={
              <>
                <Homepage />
                <div>
                  <SrfTable />
                </div>
              </>
            }
          />
          <Route
            path="/sample-receipt-tracker"
            element={
              <>
                <Homepage />
                <div>
                  <Srt />
                </div>
              </>
            }
          />
          <Route
            path="/sample-receipt-tracker/dickies"
            element={
              <>
                <Homepage />
                <div>
                  <Srt />
                </div>
              </>
            }
          />
          <Route
            path="/sample-receipt-tracker/dickies/Dropped-Rejected/*"
            element={
              <>
                <Homepage />
                <div>
                  <Srt />
                </div>
              </>
            }
          />
          <Route
            path="/sample-receipt-tracker/saucony"
            element={
              <>
                <Homepage />
                <div>
                  <Srt />
                </div>
              </>
            }
          />
          <Route
            path="/sample-receipt-tracker/saucony/Dropped-Rejected/*"
            element={
              <>
                <Homepage />
                <div>
                  <Srt />
                </div>
              </>
            }
          />
          <Route
            path="/sample-receipt-tracker/eddie-bauer"
            element={
              <>
                <Homepage />
                <div>
                  <Srt />
                </div>
              </>
            }
          />
          <Route
            path="/sample-receipt-tracker/pd"
            element={
              <>
                <Homepage />
                <div>
                  <Srt />
                </div>
              </>
            }
          />
          <Route
            path="/sample-receipt-tracker/eddie-bauer/Dropped-Rejected/*"
            element={
              <>
                <Homepage />
                <div>
                  <Srt />
                </div>
              </>
            }
          />
          <Route
            path="/sample-receipt-tracker/sales-samples"
            element={
              <>
                <Homepage />
                <div>
                  <Srt />
                </div>
              </>
            }
          />
          <Route
            path="/sample-receipt-tracker/unsolicited-samples"
            element={
              <>
                <Homepage />
                <div>
                  <Srt />
                </div>
              </>
            }
          />
          <Route
            path="/statusupdate"
            element={
              <>
                <div>
                  <StatusUpdate />
                </div>
              </>
            }
          />
        </Route>
      </Routes>
    </div>
  );
};

export default ComponentsRoutes;
