import React from 'react'
import { useMsal } from "@azure/msal-react";
import Cookies from 'js-cookie';
import { toast } from "../../UIComponents/Toast";
import './Login.css';
import { loginRequest } from "../../authConfig";
import RoyceTooLogo from '../../images/logo.jpg';

const LoginContent = ({children}) => {
  return (
    <div>
        <div className='login-background'>
        <div className='login-container'>
        <img className='royce-too-logo' src={RoyceTooLogo} alt='RoyceTooLogo' />
        <h5 className='login-heading'>External SignIn</h5>
        <div className='btn-container'>
         {/* <button onClick={nav} className='loginbtn'>Azure AD</button> */}
         {children}
        </div>
        </div>
      </div>
      {/* {Children} */}
      </div>
  )
}

export default LoginContent