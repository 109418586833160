import React from "react";
import "./Request.css";
import { useSelector } from "react-redux";
const Request = ({ indexKey, subIndex, requestDate, request }) => {
  const srtDataList = useSelector((state) => state?.Srt?.getSrtDataList);
  return (
          <div className="srt-table-data-two">
            <div key={request}>
              <p className={
                request === 0 ? "request-zero" :
                request === -1 ? "request-zero" :
                request === "-1" ? "request-zero" :
                request === null ? "request-zero" :
                request === 1 ? 
                "request-one" :
                request === 2 ? 
                "request-two" :
                request === 3 ?
                "request-three" :
                request === 4 ?
                "request-four"  :
                request === 5 ?
                "request-five" : 
                request === 6 ?
                "request-six" : 
                request === 7 ?
                "request-seven" : 
                request === 8 ?
                "request-eight" : 
                request === 9 ?
                "request-nine" : 
                request === 10 ?
                "request-ten" : 
                "request-one"
              }>{request}</p>
            </div>
          </div>
        );
};

export default Request;
