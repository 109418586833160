import * as types from "../../actionType";
import axios from "axios";
import { baseUrl } from "../../../Utiles/Helper";
import Cookies from "js-cookie";
import { toast } from "../../../UIComponents/Toast";
import WarningImg from "../../../images/warning.png";
import { client } from "../../AxiosInterceptor";
export const changingTabsWithCustomButton = (payload) => ({
  type: types.CHANING_TABS_WITH_CUSTOM_BUTTON,
  payload,
});
export const dashboardRowData = (payload) => ({
  type: types.DASHBOARD_SINGLE_ROW_DATA,
  payload,
});
export const dashboardStatusUpdate = (payload) => ({
  type: types.DASHBOARD_STATUS_UPDATE,
  payload,
});
export const resetDashboardData = (payload) => ({
  type: types.RESET_DASHBOARD_DATA,
  payload,
});
export const resetDashboardSearch = (payload) => ({
  type: types.RESET_DASHBOARD_SEARCH,
  payload,
});
export const resetDashboardStyleData = (payload) => ({
  type: types.RESET_DASHBOARD_STYLE_DATA,
  payload,
});
export const droaChecked = (payload) => ({
  type: types.DROA_CHECKED_VARIABLE,
  payload,
});
export const expandAccordion = (payload) => ({
  type: types.EXPAND_ACCORDION,
  payload,
});
export const closeEmailModal = (payload) => ({
  type: types.CLOSE_EMAIL_MODAL,
  payload,
});
const styleListLoading = () => ({
  type: types.STYLE_LIST_LOADING,
});

const styleListSuccess = (data) => ({
  type: types.STYLE_LIST_SUCCESS,
  payload: data,
});
const styleListFail = (error) => ({
  type: types.STYLE_LIST_FAIL,
  payload: error,
});
const styleListSearchLoading = () => ({
  type: types.STYLE_LIST_SEARCH_LOADING,
});

const styleListSearchSuccess = (data) => ({
  type: types.STYLE_LIST_SEARCH_SUCCESS,
  payload: data,
});
const styleListSearchFail = (error) => ({
  type: types.STYLE_LIST_SEARCH_FAIL,
  payload: error,
});
const styleListSearchFilterLoading = () => ({
  type: types.STYLE_LIST_SEARCH_FILTER_LOADING,
});

const styleListSearchFilterSuccess = (data) => ({
  type: types.STYLE_LIST_SEARCH_FILTER_SUCCESS,
  payload: data,
});
const styleListSearchFilterFail = (error) => ({
  type: types.STYLE_LIST_SEARCH_FILTER_FAIL,
  payload: error,
});
const statusSaverLoading = () => ({
  type: types.STATUS_SAVER_LOADING,
});

const statusSaverSuccess = (data) => ({
  type: types.STATUS_SAVER_SUCCESS,
  payload: data,
});
const statusSaverFail = (error) => ({
  type: types.STATUS_SAVER_FAIL,
  payload: error,
});
const styleDetailsLoading = () => ({
  type: types.STYLE_DETAILS_LOADING,
});
const styleDetailsSuccess = (data) => ({
  type: types.STYLE_DETAILS_SUCCESS,
  payload: data,
});
const styleDetailsFail = (error) => ({
  type: types.STYLE_DETAILS_FAIL,
  payload: error,
});
const droaNeededLoading = () => ({
  type: types.DROA_NEEDED_LOADING,
});
const droaNeededSuccess = (data) => ({
  type: types.DROA_NEEDED_SUCCESS,
  payload: data,
});
const droaNeededFail = (error) => ({
  type: types.DROA_NEEDED_FAIL,
  payload: error,
});
const roaReceivedLoading = () => ({
  type: types.ROA_RECEIVED_STATUS_LOADING,
});
const roaReceivedSuccess = (data) => ({
  type: types.ROA_RECEIVED_STATUS_SUCCESS,
  payload: data,
});
const roaReceivedFail = (error) => ({
  type: types.ROA_RECEIVED_STATUS_FAIL,
  payload: error,
});
const dashboardReportGenerationLoading = () => ({
  type: types.DASHBOARD_REPORT_GENERATION_LOADING,
});
const dashboardReportGenerationSuccess = (data) => ({
  type: types.DASHBOARD_REPORT_GENERATION_SUCCESS,
  payload: data,
});
const dashboardReportGenerationFail = (error) => ({
  type: types.DASHBOARD_REPORT_GENERATION_FAIL,
  payload: error,
});
const downloadEmailLoading = () => ({
  type: types.DOWNLOAD_EMAIL_LOADING,
});
const downloadEmailSuccess = (data) => ({
  type: types.DOWNLOAD_EMAIL_SUCCESS,
  payload: data,
});
const downloadEmailFail = (error) => ({
  type: types.DOWNLOAD_EMAIL_FAIL,
  payload: error,
});
const saveEmailDetailsLoading = () => ({
  type: types.SAVE_EMAIL_DETAILS_LOADING,
});
const saveEmailDetailsSuccess = (data) => ({
  type: types.SAVE_EMAIL_DETAILS_SUCCESS,
  payload: data,
});
const saveEmailDetailsFail = (error) => ({
  type: types.SAVE_EMAIL_DETAILS_FAIL,
  payload: error,
});
const deleteEmailDetailsLoading = () => ({
  type: types.DELETE_EMAIL_DETAILS_LOADING,
});
const deleteEmailDetailsSuccess = (data) => ({
  type: types.DELETE_EMAIL_DETAILS_SUCCESS,
  payload: data,
});
const deleteEmailDetailsFail = (error) => ({
  type: types.DELETE_EMAIL_DETAILS_FAIL,
  payload: error,
});
const sendEmailLoading = () => ({
  type: types.SEND_EMAIL_LOADING,
});
const sendEmailSuccess = (data) => ({
  type: types.SEND_EMAIL_SUCCESS,
  payload: data,
});
const sendEmailFail = (error) => ({
  type: types.SEND_EMAIL_FAIL,
  payload: error,
});
const getSavedEmailLoading = () => ({
  type: types.GET_SAVED_EMAIL_LOADING,
});
const getSavedEmailSuccess = (data) => ({
  type: types.GET_SAVED_EMAIL_SUCCESS,
  payload: data,
});
const getSavedEmailFail = (error) => ({
  type: types.GET_SAVED_EMAIL_FAIL,
  payload: error,
});
const emailStatusLoading = () => ({
  type: types.EMAIL_STATUS_LOADING,
});
const emailStatusSuccess = (data) => ({
  type: types.EMAIL_STATUS_SUCCESS,
  payload: data,
});
const emailStatusFail = (error) => ({
  type: types.EMAIL_STATUS_FAIL,
  payload: error,
});

const tpDropdownUpdateLoading = () => ({
  type: types.TP_STATUS_DROPDOWN_UPDATE_LOADING,
});
const tpDropdownUpdateSuccess = (data) => ({
  type: types.TP_STATUS_DROPDOWN_UPDATE_SUCCESS,
  payload: data,
});
const tpDropdownUpdateFail = (error) => ({
  type: types.TP_STATUS_DROPDOWN_UPDATE_FAIL,
  payload: error,
});

let url = process.env.REACT_APP_BASEURL;

export const dashboardStyleList = () => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "GET",
      url: `style/list`,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(styleListLoading());
    client(Config)
      .then((resp) => {
        dispatch(styleListSuccess(resp.data));
        resp?.data?.error
          ? toast(resp?.data?.error, {
              icon: (
                <img
                  src={WarningImg}
                  alt="warning-icon"
                  height={25}
                  width={25}
                />
              ),
            })
          : "";
      })
      .catch((error) => {
        console.log("errorforlsit", error);
        dispatch(styleListFail(error));
      });
  };
};

export const dashboardStyleListSearch = (search, season) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `style/search?search=${search}&season=${season}`,
      search,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(styleListSearchLoading());
    client(Config)
      .then((resp) => {
        if (resp.data === "") {
          dispatch(dashboardStyleList());
          toast.error("Data Not Found");
        } else {
          dispatch(styleListSearchSuccess(resp.data));
        }
      })
      .catch((error) => {
        dispatch(styleListSearchFail(error));
        toast.error("Item Was Not Found");
      });
  };
};

export const dashboardStyleListSearchFilter = (season) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `style/search?search=${season}`,
      season,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(styleListSearchFilterLoading());
    client(Config)
      .then((resp) => {
        dispatch(styleListSearchFilterSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(styleListSearchFilterFail(error));
      });
  };
};

export const dashboardStatusSaver = (data) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `style/details/status_saver/`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(statusSaverLoading());
    client(Config)
      .then((resp) => {
        dispatch(statusSaverSuccess(resp?.data));
        toast.success(resp?.data?.info, {
          style: {
            maxWidth: 520,
          },
        });
      })
      .catch((error) => {
        dispatch(statusSaverFail(error));
        toast.error(
          error?.response?.data?.info
            ? error?.response?.data?.info
            : "Could'nt update the Status"
        );
      });
  };
};

export const dashboardStyleDetailsList = (style, season_id, season, brand) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "GET",
      url: `style/details?style=${style}&season_id=${season_id}&season=${season}&brand=${brand}`,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(styleDetailsLoading());
    client(Config)
      .then((resp) => {
        dispatch(styleDetailsSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(styleDetailsFail(error));
        toast.error("Style Details Not Found");
      });
  };
};

export const droaStatusSave = (
  style,
  factory,
  droa_needed,
  singleRowDataFromDashboard,
  season
) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `style/droa/status/save/?style=${style}&factory=${factory}&droa_needed=${droa_needed}&season=${season}`,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(droaNeededLoading());
    client(Config)
      .then((resp) => {
        dispatch(droaNeededSuccess(resp.data));
        dispatch(
          dashboardStyleDetailsList(
            style,
            singleRowDataFromDashboard?.season_id,
            singleRowDataFromDashboard?.season,
            singleRowDataFromDashboard?.brand
          )
        );
        toast.success("DROA check status saved successfully");
      })
      .catch((error) => {
        dispatch(droaNeededFail(error));
        // toast.error("Style Details Not Found")
      });
  };
};

export const roaStatusSave = (data, singleRowDataFromDashboard) => {
  console.log(
    "data, singleRowDataFromDashboard",
    data,
    singleRowDataFromDashboard
  );
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `style/received/status`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(roaReceivedLoading());
    client(Config)
      .then((resp) => {
        dispatch(roaReceivedSuccess(resp.data));
        try {
          dispatch(
            dashboardStyleDetailsList(
              data.style, // Assuming data.style holds the 'style' value
              singleRowDataFromDashboard?.season_id,
              singleRowDataFromDashboard?.season,
              singleRowDataFromDashboard?.brand
            )
          );
        } catch (error) {
          console.error("Error in dashboardStyleDetailsList:", error);
        }
        toast.success("DROA/ROA received status saved successfully");
      })
      .catch((error) => {
        dispatch(roaReceivedFail(error));
        // toast.error("Style Details Not Found")
      });
  };
};

export const techPackEmailUpdate = (data) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `updated/samples`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(updateSampleEmailLoading());
    client(Config)
      .then((resp) => {
        dispatch(updateSampleEmailSuccess(resp.data));
        toast.success("Mail sent successfully");
      })
      .catch((error) => {
        dispatch(updateSampleEmailFail(error));
        toast.error("Fail to send Mail");
      });
  };
};

export const getSavedEmail = (style, vendor, season) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "GET",
      url: `save/email/draft?style=${style}&factory=${vendor}&season=${season}`,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(getSavedEmailLoading());
    client(Config)
      .then((resp) => {
        dispatch(getSavedEmailSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(getSavedEmailFail(error));
      });
  };
};

export const sendEmail = (data) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `updated/samples`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(sendEmailLoading());
    client(Config)
      .then((resp) => {
        dispatch(sendEmailSuccess(resp.data));
        dispatch(closeEmailModal(false));
        toast.success("Mail sent successfully");
      })
      .catch((error) => {
        dispatch(sendEmailFail(error));
        toast.error("Failed to send Mail");
      });
  };
};

export const SaveEmailDetails = (data, style, vendor, season) => {
  console.log("datalogged", data);
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `save/email/draft`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(saveEmailDetailsLoading());
    client(Config)
      .then((resp) => {
        dispatch(saveEmailDetailsSuccess(resp.data));
        toast.success("Email saved successfully");
        dispatch(getSavedEmail(style, vendor, season));
      })
      .catch((error) => {
        dispatch(saveEmailDetailsFail(error));
        toast.error("Failed to save Email");
      });
  };
};

export const DeleteEmailDetails = (data, style, factory, season) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `saved/request/files`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(deleteEmailDetailsLoading());
    client(Config)
      .then((resp) => {
        dispatch(deleteEmailDetailsSuccess(resp.data));
        dispatch(getSavedEmail(data?.style, data?.factory, season));
        toast.success("File deleted successfully");
      })
      .catch((error) => {
        dispatch(deleteEmailDetailsFail(error));
        toast.error("Failed to delete File");
      });
  };
};

export const DownloadEmail = (style, vendor, filename) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "GET",
      url: `saved/request/files?style=${style}&vendor=${vendor}&filename=${filename}`,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(downloadEmailLoading());
    client(Config)
      .then((resp) => {
        dispatch(downloadEmailSuccess(resp.data));

        const getFileType = (filename) => {
          const extension = filename.split(".").pop().toLowerCase();
          if (
            extension === "png" ||
            extension === "jpg" ||
            extension === "jpeg"
          ) {
            return "image/png"; // Adjust the MIME type based on your needs
          } else if (extension === "pdf") {
            return "application/pdf";
          } else if (extension === "xlsx" || extension === "xls") {
            return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          } else {
            // Default to binary data if the file type is not recognized
            return "application/octet-stream";
          }
        };

        const fileType = getFileType(".PNG");
        const blob = new Blob([fileData], { type: fileType });
        saveAs(blob, ".png");

        toast.success("Email Downloaded successfully");
      })
      .catch((error) => {
        dispatch(downloadEmailFail(error));
        toast.error("Failed to Download Email");
      });
  };
};

export const reportGeneration = () => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "GET",
      url: `/dashboard/report`,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
      responseType: "arraybuffer",
    };
    dispatch(dashboardReportGenerationLoading());
    client(Config)
      .then((resp) => {
        dispatch(dashboardReportGenerationSuccess(resp.data));
        toast.success("Report Generated Successfully");
        var blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var fileName = "Dashboard Report.xlsx";
        saveAs(blob, fileName);
      })
      .catch((error) => {
        dispatch(dashboardReportGenerationFail(error));
        toast.error("Data Not Found");
      });
  };
};

export const emailStatusUpdate = (style, vendor) => {
  console.log("here is the email");
  // let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    // const Config = {
    //   method: "GET",
    //   url: `/dashboard/report`,
    //   // headers: {
    //   //   Authorization: `Bearer ${secure_token}`,
    //   // },
    // };
    // client(Config)
    dispatch(emailStatusLoading());
    axios
      .get(
        `https://3e17b5dd-f58f-4fab-96c2-ca55178893ac.mock.pstmn.io/request/details?style=${style}&vendor=${vendor}`
      )
      .then((resp) => {
        dispatch(emailStatusSuccess(resp.data));
        toast.success("Report Generated Successfully");
      })
      .catch((error) => {
        dispatch(emailStatusFail(error));
        // toast.error("Data Not Found");
      });
  };
};

export const tpDropdownStatusUpdate = (data, singleRowDataFromDashboard) => {
  let secure_token = Cookies.get("access_token");
  return function (dispatch) {
    const Config = {
      method: "POST",
      url: `/update/tp/status`,
      data,
      headers: {
        Authorization: `Bearer ${secure_token}`,
      },
    };
    dispatch(tpDropdownUpdateLoading());
    client(Config)
      .then((resp) => {
        dispatch(tpDropdownUpdateSuccess(resp.data));
        toast.success("Tech Pack status saved successfully");
      })
      .catch((error) => {
        dispatch(tpDropdownUpdateFail(error));
        toast.error("Could not save the Tech Pack");
      });
  };
};
