import React from 'react'
import { Select } from 'antd';
const SrtDropDown = ({allowClear,placeholder,value,onChange,options,defaultValue}) => {

  return (
    <div>
    <Select
    allowClear={allowClear}
    placeholder={placeholder}
    value={value}
      defaultValue={defaultValue}
      style={{
        width: 180,
      }}
      onChange={onChange}
      options={options}
    />
    </div>
  )
}

export default SrtDropDown