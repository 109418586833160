import * as types from "../../actionType";

const initialState = {
  getSrfTableList: [],
  srfSaveData: [],
  srfSearchData: [],
  srfSampleRequest: "",
  srfEditData: {},
  srfTableListLoader: false,
  searchLoader: false,
  srfExportData: {},
  sendSampleLoader: false,
  srfExfactoryDateVar: "",
  srfExportRowData: [],
  requestSectionData: false,
  srfRefreshSpin : false,
  disableToggleSrf: false,
  srfFieldsReset: {},
  srfMultiSaveData: {},
  srfDeleteColor:{},
};

export const SrfReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SRF_EXFACTORY_DATE:
      return {
        ...state,
        srfExfactoryDateVar: action.payload,
        loading: false,
      };
    case types.SRF_DISABLE_TOGGLE:
      return {
        ...state,
        disableToggleSrf: action.payload,
        loading: false,
      };
    case types.RESET_SRF_SEARCH_DATA:
      return {
        ...state,
        srfSearchData: [],
        loading: false,
      };
    case types.RESET_SRF_LIST:
      return{
        ...state,
        getSrfTableList: [],
        loading: false,
      }
    case types.RESET_SRF_FIELDS:
      return {
        ...state,
        srfFieldsReset: action.payload,
        loading: false,
      };
    case types.GET_SRF_LIST_LOADING:
      return {
        ...state,
        loading: true,
        srfTableListLoader: true,
        srfRefreshSpin:true,
      };
    case types.GET_SRF_LIST_SUCCESS:
      return {
        ...state,
        getSrfTableList: action.payload,
        loading: false,
        srfTableListLoader: false,
        srfRefreshSpin:false,
      };
    case types.GET_SRF_LIST_FAIL:
      return {
        ...state,
        loading: false,
        getSrfTableList: [],
        srfTableListLoader: false,
        srfRefreshSpin:false,
      };
    case types.SRF_SAVE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SRF_SAVE_SUCCESS:
      return {
        ...state,
        srfSaveData: action.payload,
        loading: false,
      };
    case types.SRF_SAVE_FAIL:
      return {
        ...state,
        loading: false,
        srfSaveData: [],
      };
    case types.MULTI_SRF_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.MULTI_SRF_SUCCESS:
      return {
        ...state,
        srfMultiSaveData: action.payload,
        loading: false,
      };
    case types.MULTI_SRF_FAIL:
      return {
        ...state,
        loading: false,
        srfMultiSaveData: [],
      };
    case types.SRF_SEARCH_FILTER_LOADING:
      return {
        ...state,
        loading: true,
        searchLoader: true,
      };
    case types.SRF_SEARCH_FILTER_SUCCESS:
      return {
        ...state,
        srfSearchData: action.payload,
        loading: false,
        searchLoader: false,
      };
    case types.SRF_SEARCH_FILTER_FAIL:
      return {
        ...state,
        loading: false,
        srfSearchData: [],
        searchLoader: false,
      };
    case types.SRF_SEND_SAMPLE_REQUEST_LOADING:
      return {
        ...state,
        loading: true,
        sendSampleLoader: true,
      };
    case types.SRF_SEND_SAMPLE_REQUEST_SUCCESS:
      return {
        ...state,
        srfSampleRequest: action.payload,
        loading: false,
        sendSampleLoader: false,
      };
    case types.SRF_SEND_SAMPLE_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        srfSampleRequest: [],
        sendSampleLoader: false,
      };
    case types.SRF_EDIT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SRF_EXPORT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SRF_EXPORT_SUCCESS:
      return {
        ...state,
        srfExportData: action.payload,
        loading: false,
      };
    case types.SRF_EXPORT_FAIL:
      return {
        ...state,
        loading: false,
        srfExportData: {},
      };
    case types.DELETE_SRF_COLOR_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_SRF_COLOR_SUCCESS:
      return {
        ...state,
        srfDeleteColor: action.payload,
        loading: false,
      };
    case types.DELETE_SRF_COLOR_FAIL:
      return {
        ...state,
        loading: false,
        srfDeleteColor: {},
      };
    case types.EXPORT_ROW_DATA:
      return {
        ...state,
        srfExportRowData: action.payload,
        loading: false,
      };
    case types.REQUEST_SECTION_RESET:
      return {
        ...state,
        requestSectionData: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
