import * as types from '../../actionType';

const initialState = {
    profileDetails:{},
    graphCompleteData: {}
}

export const ProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_GRAPH_DATA_LOADING:
            return{
                ...state,
                loading: true,
                profileDetails:true,
            }
        case types.GET_GRAPH_DATA_SUCCESS:
            return{
                ...state,
                graphCompleteData:action.payload,
                loading:false,
            }
        case types.GET_GRAPH_DATA_FAIL:
            return{
                ...state,
                error:action.payload,
                loading:false,
                graphCompleteData:{},
            }
        case types.PROFILE_DETAIL_LOADING:
            return{
                ...state,
                loading: true,
                profileDetails:true,
            }
        case types.PROFILE_DETAIL_SUCCESS:
            return{
                ...state,
                profileDetails:action.payload,
                loading:false,
            }
        case types.PROFILE_DETAIL_FAIL:
            return{
                ...state,
                error:action.payload,
                loading:false,
                profileDetails:{},
            }
            default:
                return state;
        }
    }